import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { SellerService } from '../../../seller.service';

declare const isEmpty: any;

@Injectable()
export class SellerDadosCotacaoNotasFiscaisResolve  {

    constructor(private service: SellerService) { }

    async resolve(route: ActivatedRouteSnapshot)
    {
        let order_id = parseInt(route.params['id']);
        let result = this.service.dadosCotacaoPedidoSelecionado;

        if (isEmpty(result))
        {
            result = await this.service.obter(order_id);

            if (!isEmpty(result))
            {
                this.service.dadosCotacaoPedidoSelecionado = result.pedido;
                this.service.finalizarObtencaoPedido(result.pedido, result.fotos, order_id);

                return this.service.dadosCotacaoPedidoSelecionado;
            }
        }

        return result;
    }
}
