import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

import { SellerCondicaoFornecimentoPostModel } from '../_models/sellerCondicaoFornecimentoPostModel';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class SellerConfigurarCondicoesFornecimentoService extends BaseService {
    private _domain: string = '/seller/condicoes-fornecimento';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public getAll(): Observable<any> {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        return this.config.executeGet({ url: this._domain });
    }

    public save(model: SellerCondicaoFornecimentoPostModel): Observable<any> {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        model.holding_id = this.usuarioLogado.holding_id;

        return this.config.executePost({ url: this._domain, body: model });
    }
}
