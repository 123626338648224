import { EventEmitter, Injectable } from '@angular/core';

import { SignalRMessage } from '../../_models/signalrMessage';
import { AccountService } from '../../account/account.service';
import { SettingsService } from '../../_services/settings.service';
import { SignalRService } from '../../_services/signalr.service';

@Injectable()
export class SellerSignalRService extends SignalRService
{
    AtualizarDashboardVendedorEvent = new EventEmitter<any>();

    constructor(accountService: AccountService, settingsService: SettingsService)
    {
        super(accountService, settingsService);
        //console.log('sellersignalrservice');

        this.registerOnServerEvents();
    }

    private registerOnServerEvents(): void
    {
        this._hubConnection.on("AtualizarDashboardVendedorEvent", (user_ids, message) =>
        {
            if (this.enableSignalRLog)
                console.log('SellerSignalRService.AtualizarDashboardVendedorEvent', user_ids, message);

            if (this.is_toConnectedUser(user_ids))
                this.AtualizarDashboardVendedorEvent.emit(message);
        });
    }
}
