import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({ selector: 'app-commerce-edit-all', templateUrl: './edit-all.component.html', styleUrls: ['./edit-all.component.scss'] })
export class SellerCommerceEditAllComponent {
    @ViewChild('templateEditAll') template: TemplateRef<any>;
    @Input() productsCount: number;
    @Output() editProducts = new EventEmitter<{cashback: number, discount: number}>();

    isLoading: boolean = false;
    modalRef: BsModalRef;
    editionForm: UntypedFormGroup;

    constructor(private modalService: BsModalService, private formBuilder: UntypedFormBuilder) {}

    async close(): Promise<void> {
        this.modalRef.hide()
        this.isLoading = false;
    }

    open() {
        this.editionForm = this.formBuilder.group({
            discount: [0, [Validators.required, Validators.min(0), Validators.max(99.99)]],
            cashback: [0, [Validators.required, Validators.min(0), Validators.max(99.99)]],
        });

        let options: ModalOptions = { class: 'custom-modal', backdrop: 'static', keyboard: false };
        this.modalRef = this.modalService.show(this.template, options);
        this.isLoading = false;
    }

    save() {
        this.isLoading = true;

        this.editProducts.emit({
            cashback: this.editionForm.value.cashback,
            discount: this.editionForm.value.discount
        });
    }
}
