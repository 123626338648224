import { Component, OnInit } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import { Globals } from '../../_models/globals';
import { Transportadora } from '../../_models/transportadora';

import { NotificationService } from '../../_services/notification.service';
import { SellerPedidosService } from './pedidos.service';
import { SellerService } from '../seller.service';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { GetAllOrdersResponseModel } from './_models/getAllOrdersResponseModel';
import { SellerOrdersGetResponseModel } from '../detalhe/_models/sellerOrdersGetResponseModel';

declare const deepSetObj: any;
declare const isEmpty: any;

@Component({ selector: 'app-pedidos', templateUrl: './pedidos.component.html', styleUrls: ['./pedidos.component.scss'] })
export class SellerPedidosComponent implements OnInit {
    activeAccordionItemIndex: number | null;
    filtro: string;
    filtro_dias: string = "15";
    pedidos: any;
    primeira_vez: boolean;
    transportadoras: Transportadora[] = [];

    constructor(private notificationService: NotificationService, private pedidosService: SellerPedidosService, private sellerService: SellerService, private globals: Globals, private ngxLoader: NgxUiLoaderService,
        private carouselConfig: NgbCarouselConfig)
    {
        this.carouselConfig.interval = 200000;
    }

    ngOnInit() {
        this.pedidos = [];
        this.primeira_vez = true;
    }

    atualizar(order_id, nAttempts) {
        var pedido = this.pedidos.find(p => p.order_id === order_id);

        if (isEmpty(pedido))
            return;

        pedido.loading_pedido = true;

        this.sellerService.getOrderDetail(pedido.order_id).subscribe({
            next: (response: ApiResponseModel<SellerOrdersGetResponseModel>) => {
                pedido.loading_pedido = false;

                if (response != null && response.success) {
                    deepSetObj(pedido, response.result.pedido);
                    this.sellerService.finalizarObtencaoPedido(pedido, response.result.fotos, null);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    pedido.loading_pedido = false;
                    this.notificationService.showErrorToastr(this.globals.translate('seller.pedidos.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.filtrar(++nAttempts);
            }
        });
    }

    atualizarEvent($event) {
        this.atualizar($event.order_id, 0);
    }

    filtrar(nAttempts) {
        this.ngxLoader.startLoader('loader-principal');
        let filtro = this.filtro.replace('-', '');

        this.pedidosService.getAll(filtro, parseInt(this.filtro_dias)).subscribe({
            next: (response: ApiResponseModel<GetAllOrdersResponseModel>) =>
            {
                this.primeira_vez = false;

                if (response != null && response.success)
                {
                    this.pedidos = response.result.vendedorPedido.length > 0 ? response.result.vendedorPedido : [];

                    for (let pedido of this.pedidos)
                        this.sellerService.finalizarObtencaoPedido(pedido, [], null);

                    this.transportadoras = response.result.transportadoras;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error =>
            {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.pedidos.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.filtrar(++nAttempts);
            }
        });
    }

    togglePedido(pedido, pedidoIndex) {
        this.activeAccordionItemIndex = pedidoIndex != this.activeAccordionItemIndex ? pedidoIndex : null

        if (pedido.fotos == undefined || pedido.fotos.length == 0)
        {
            this.atualizar(pedido.order_id, 0);
        }
    }
}
