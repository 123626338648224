<div class="vendedor-notas-fiscais" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="('seller.notas-fiscais.titulo' | translate) + pedido?.marca + ' - ' + pedido?.veiculo"
        (onGoBack)="goToPedido($event)"
    >
        <span class="inserir-xml" (click)="inserirNota($event)">
            <a href="javascript:void(0)">{{ 'seller.dashboard.cards.nota.inserir' | translate}}</a>
            <input type="file" accept="text/xml" (change)="inserirNotaGravar($event, pedido)" />
        </span>
    </app-header>
    <div class="card card-default d-flex flex-wrap">
        @if (sellerService?.dadosCotacaoPedidoSelecionado?.inconsistencias?.length > 0) {
            <div class="col-md-6">
                <div ngbAccordion accordion="NgbAccordion" class="ngbAccordion" [closeOthers]="true">
                    <div ngbAccordionItem="panelInconsistencias" [collapsed]="false">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                {{ 'seller.notas-fiscais.panel.titulo.inconsistencia' | translate}}
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <section ngbAccordionBody class="d-flex flex-wrap striped bordered inconsistencia">
                                <ng-template>
                                    <header>
                                        <div class="col-5">{{ 'seller.notas-fiscais.panel.inconsistencias.texto' | translate}}</div>
                                    </header>
                                    <ng-scrollbar class="corpo">
                                        @for (inconsistencia of sellerService?.dadosCotacaoPedidoSelecionado.inconsistencias; track inconsistencia) {
                                            <div class="linha">
                                                <div class="col-5 truncate">{{inconsistencia.texto}}</div>
                                            </div>
                                        }
                                    </ng-scrollbar>
                                </ng-template>
                            </section>
                        </div>
                    </div>
                    <div ngbAccordionItem="panelPecas">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                {{ 'seller.notas-fiscais.panel.titulo.peca' | translate}}
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <section ngbAccordionBody class="d-flex flex-wrap striped bordered">
                                <ng-template>
                                    <header>
                                        <div class="col-2-5">{{ 'seller.notas-fiscais.panel.pecas.nome' | translate}}</div>
                                        <div class="col text-center">{{ 'seller.notas-fiscais.panel.pecas.entrega' | translate}}</div>
                                        <div class="col-0-5 text-center">{{ 'seller.notas-fiscais.panel.pecas.quantidade' | translate}}</div>
                                        <div class="col-1-5 text-center">{{ 'seller.notas-fiscais.panel.pecas.vendedor-preco' | translate}}</div>
                                        <div class="col text-center">{{ 'seller.notas-fiscais.panel.pecas.vendedor-total' | translate}}</div>
                                    </header>
                                    <ng-scrollbar class="corpo">
                                        @for (peca of pedido.pecas; track peca) {
                                            <div class="linha">
                                                <div class="col-2-5 truncate">{{peca.peca}}</div>
                                                <div class="col-1-5">{{peca.prazo_entrega}}</div>
                                                <div class="col-0-5">{{peca.quantidade}}</div>
                                                <div class="col text-end">{{(peca.valor_unitario * (1 - (peca.desconto / 100))) | currency:' ' }}</div>
                                                <div class="col text-end">{{(peca.quantidade * (peca.valor_unitario * (1 - (peca.desconto / 100)))) | currency:' '}}</div>
                                            </div>
                                        }
                                    </ng-scrollbar>
                                    <footer class="linha">
                                        <div class="linha">
                                            <div class="col-4"></div>
                                            <div class="col">{{ 'seller.notas-fiscais.panel.total-pedido' | translate}}: </div>
                                            <div class="col text-end">{{pedido?.total | currency:' '}}</div>
                                        </div>
                                    </footer>
                                </ng-template>
                            </section>
                        </div>
                    </div>
                    <div ngbAccordionItem="panelVendedor">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                {{ 'seller.notas-fiscais.panel.titulo.dados-comprador' | translate}}
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="mb10 p-1">
                                <ng-template>
                                    <div class="row">
                                        <div class="label text-bold text-end">
                                            {{ 'seller.notas-fiscais.panel.dados-comprador.comprador-empresa' | translate}}:
                                        </div>
                                        <div class="col-md-8">
                                            {{pedido.comprador_empresa}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="label text-bold text-end">
                                            {{ 'seller.notas-fiscais.panel.dados-comprador.fone' | translate}}:
                                        </div>
                                        <div class="col-md-8">
                                            {{pedido.buyer_phone_radio}}
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        <div class="lista-notas-fiscais" [ngClass]="{ 'com-inconsistencia': sellerService?.dadosCotacaoPedidoSelecionado?.inconsistencias?.length > 0, 'col-md-6': sellerService?.dadosCotacaoPedidoSelecionado?.inconsistencias?.length > 0, 'col-md-12': sellerService?.dadosCotacaoPedidoSelecionado?.inconsistencias?.length == 0 }">
            <app-nota-fiscal [pedido]="nfService.pedidosNotaFiscal$ | async" [transportadoras]="transportadoras" [is_central]="false" [is_seller]="true" (excluirNota)="excluirNota($event)" (salvarTransportadora)="salvarTransportadora($event)"></app-nota-fiscal>
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
