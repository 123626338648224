import { Injectable } from '@angular/core';

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';
import { Product } from './_models/product';
import { v4 as uuid } from 'uuid';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SellerCommerceService extends BaseService
{
    private _apiCommerceUrl: string = `${environment.apiCommerce}`;
    private _domain: string = '/admin/products';
    private _pageSize: number = 10;

    constructor(private config: SettingsService, private http: HttpClient)
    {
        super();
    }

    public getByPage(pageNumber: number, filter: string = '') {
        return this.config.executeGet({ baseUrl: this._apiCommerceUrl, url: `${this._domain}?currentPage=${pageNumber}&pageSize=${this._pageSize}&partNameFilter=${filter}`});
    }

    public editAll(cashback: number, discount: number) {
        return this.config.executePut({ baseUrl: this._apiCommerceUrl, url: `${this._domain}/holdings/${this.usuarioLocal.company_id}`, body: { cashback, discount } });
    }

    public createProduct(product: Product) {
        if (product.productId == null)
            product.productId = uuid();
        
        return this.config.executePost({ baseUrl: this._apiCommerceUrl, url: this._domain, body: product });
    }

    public editProduct(product: Product) {
        return this.config.executePut({ baseUrl: this._apiCommerceUrl, url: `${this._domain}/${product.productId}`, body: product });
    }

    public getImageData(imageUrl: string): Promise<{ base64: string, contentType: string }> {
        return this.http.get(imageUrl, { responseType: 'blob' }).toPromise()
          .then(blob => this.blobToBase64(blob).then(base64 => {
            const contentType = blob.type;
            const base64WithoutPrefix = base64.split(',')[1];    
            return { base64: base64WithoutPrefix, contentType };
          }));
    }
    
    private blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }    
}
