import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AccountService } from 'app/account/account.service';
import { NotaFiscalService } from 'app/_componentes/notafiscal/notafiscal.service';
import { SellerDashboardService } from '../../../../dashboard/dashboard.service';
import { SellerService } from '../../../../seller.service';

import { LocalStorageUtils } from 'app/_utils/localstorage';
import { Transportadora } from 'app/_models/transportadora';
import { User } from 'app/_models/user';
import { NotaFiscalComponent } from 'app/_componentes/notafiscal/notafiscal.component';

declare const $: any;

@Component({ selector: 'app-notas-fiscais', templateUrl: './notas-fiscais.component.html', styleUrls: ['./notas-fiscais.component.scss'] })
export class SellerDadosCotacaoNotasFiscaisComponent implements OnInit {
    @ViewChild(NgbAccordionDirective) accordion: NgbAccordionDirective;
    @ViewChild(NotaFiscalComponent) nfComponent: NotaFiscalComponent;

    private localStorageUtils = new LocalStorageUtils();
    currentUser: User;
    currentLocalUser: User;
    pedido: any;
    transportadoras: Transportadora[] = [];
    pdfSrc: string;
    visaoPDF: boolean;

    constructor(private accountService: AccountService, private dashboardService: SellerDashboardService, public sellerService: SellerService, public nfService: NotaFiscalService,
        public activatedRoute: ActivatedRoute, private ngxLoader: NgxUiLoaderService, private router: Router)
    {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state)
        {
            const currentState = this.router.getCurrentNavigation().extras.state;

            this.pedido = currentState.pedido;
            this.transportadoras = currentState.transportadoras;
        }
    }

    ngOnInit(): void {
        if (!this.pedido || !this.transportadoras)
            this.goToPedido(null);

        this.currentUser = this.localStorageUtils.obterUsuario();
        this.currentLocalUser = this.localStorageUtils.obterUsuarioLocal();

        this.calcularTotalPedido(this.pedido);

        this.visaoPDF = this.pedido.notasFiscais.length === 1;

        if (this.pedido.notasFiscais.length > 0)
            this.pdfSrc = this.pedido.notasFiscais[0].url;

        this.nfService.setPedidoNotaFiscal(this.pedido);
    }

    async atualizarPedido(orderId, sellerService, ngxLoader, calcularTotalPedido) {
        ngxLoader.startLoader('loader-principal');

        let response = await sellerService.obter(orderId);

        if (response && response.success)
        {
            const result = response.result;

            sellerService.dadosCotacaoPedidoSelecionado = result.pedido;
            sellerService.finalizarObtencaoPedido(result.pedido, result.fotos, orderId);

            calcularTotalPedido(sellerService.dadosCotacaoPedidoSelecionado);
        }

        ngxLoader.stopLoader('loader-principal');
    }

    calcularTotalPedido(pedido) {
        let total = pedido.pecas.length > 0 ? pedido.pecas.map(p => (p.quantidade * (p.valor_unitario * (1 - (p.desconto / 100))))).reduce((acumulador, valor) => acumulador += valor) : 0;
        pedido.total = total;
    }

    excluirNota($event) {
        let atualizarPedido = this.atualizarPedido;
        let orderId = this.pedido.order_id;
        let sellerService = this.sellerService;
        let ngxLoader = this.ngxLoader;
        let calcularTotalPedido = this.calcularTotalPedido;

        ngxLoader.startLoader('loader-principal');

        this.dashboardService.excluirNF($event.nota_url, this.pedido, function () { atualizarPedido(orderId, sellerService, ngxLoader, calcularTotalPedido); });
    }

    inserirNota($event) {
        $($event.target).next().click();
    }

    async inserirNotaGravar($event, pedido) {
        this.ngxLoader.startLoader('loader-principal');

        let nfService = this.nfService;
        let atualizarPedido = this.atualizarPedido;
        let orderId = this.pedido.order_id;
        let sellerService = this.sellerService;
        let ngxLoader = this.ngxLoader;
        let calcularTotalPedido = this.calcularTotalPedido;
        let nfComponent = this.nfComponent;

        this.dashboardService.incluirNF($event, pedido, nfComponent,
            function (pedido) { nfService.setPedidoNotaFiscal(pedido); },
            function () { atualizarPedido(orderId, sellerService, ngxLoader, calcularTotalPedido); }
        );
    }

    goToPedido($event) {
        if ($event)
            $event.stopPropagation();

        if (!this.pedido)
            this.pedido = this.activatedRoute.snapshot.data['pedido'];

        this.router.navigateByUrl('vendedor/visao-geral/detalhe/' + this.pedido.order_id);
    }

    salvarTransportadora($event: any) {
        let nfService = this.nfService;

        const dadosTransportadora = {
            shipping_company_id: $event.shipping_company_id,
            shipping_company_name: $event.shipping_company_name,
            shipping_company_tracking_code: $event.shipping_company_tracking_code,
            nota_fiscal: $event.nota_fiscal
        }

        this.dashboardService.setShippingCompany(this.pedido, dadosTransportadora, function (pedidoCallback) { nfService.setPedidoNotaFiscal(pedidoCallback); });
    }

    selecionarNota(nota) {
        this.pdfSrc = nota.url;
        this.visaoPDF = true;
    }
}
