import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';
import { SellerOrderService } from 'app/seller/_services/order.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class SellerDadosCotacaoService extends BaseService {
    private _domain: string = '/seller/dashboard';

    constructor(private accountService: AccountService, private confirmarService: SellerOrderService, private config: SettingsService) {
        super();
    }

    public confirmDelivery(quotation_id: number, pecas_ids: number[]): Observable<any> {
        if (isEmpty(this.usuarioLogado)) {
            this.accountService.logout();
            return EMPTY;
        }

        const seller_company_id = this.usuarioLogado.user_company_id;
        const body = { seller_company_id, quotation_id, pecas_ids };

        return this.config.executePost({ url: `${this._domain}/confirm-delivery`, body });
    }

    public getRejectReasons(status_id: number): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/reject-order-reasons/${status_id}` });
    }

    public rejectItens(order_id: number, pecas_ids: number[], cancel_reason_id: number, notes: string = null): Observable<any> {
        return this.confirmarService.rejectItens(order_id, pecas_ids, cancel_reason_id, notes);
    }
}
