import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AccountService } from '../../account/account.service';
import { SellerDashboardService } from './dashboard.service';
import { SellerSignalRService } from '../_services/seller.signalr.service';

import { getScreenSizeIsMobile, inserirCotacaoNaLista } from '../../_utils/general-utils';

import { Globals } from '../../_models/globals';
import { StatusIdEnum } from '../../_models/statusId';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { DashboardQuotationModel } from './_models/dashboardsQuotationModel';
import { LastInventoryUpdateDateModel } from './_models/lastInventoryUpdateDateModel';
import { NotaFiscalComponent } from 'app/_componentes/notafiscal/notafiscal.component';
import { Subscription } from 'rxjs';

declare const $: any;
declare const deepSetObj: any;
declare const isEmpty: any;
declare const getJavascriptDateFromCSharpDate: any;
declare const sort_by: any;

@Component({ selector: 'app-dashboard', templateUrl: './dashboard.component.html', styleUrls: ['./dashboard.component.scss'] })

export class SellerDashboardComponent implements OnInit, OnDestroy
{
    @ViewChild(NotaFiscalComponent) nfComponent: NotaFiscalComponent;

    // tslint:disable-next-line: max-line-length
    constructor(private activatedRoute: ActivatedRoute, public accountService: AccountService, private dashboardService: SellerDashboardService,
        private sellerSignalRService: SellerSignalRService, private globals: Globals, private ngxLoader: NgxUiLoaderService, private router: Router)
    { }

    inserirCotacaoNaLista: any = inserirCotacaoNaLista;

    getDashboard: any;
    private _searchTerm: string;
    quotations: DashboardQuotationModel[] = [];
    filteredQuotations: DashboardQuotationModel[] = [];
    column01Quotations: DashboardQuotationModel[] = [];
    column02Quotations: DashboardQuotationModel[] = [];
    column03Quotations: DashboardQuotationModel[] = [];
    column04Quotations: DashboardQuotationModel[] = [];

    lastInventoryUpdateDate: LastInventoryUpdateDateModel;

    private subscriptionAtualizarDashboardVendedorEvent: Subscription;
    public menor1024: boolean;

    get StatusIdEnum(): typeof StatusIdEnum {
        return StatusIdEnum;
    }

    ngOnInit()
    {
        if (!this.accountService.checkIfIsLogged())
        {
            this.ngxLoader.startLoader('loader-principal');
            return;
        }

        this.registerObservables();
        this.inicializarTela(0);
        this.menor1024 = getScreenSizeIsMobile()
    }

    ngOnDestroy()
    {
        if (!isEmpty(this.getDashboard))
            this.getDashboard.unsubscribe();

        this.subscriptionAtualizarDashboardVendedorEvent.unsubscribe();
    }

    atualizarDadosCotacao(cotacao)
    {
        if (cotacao.coluna >= 1)
        {
            cotacao.closest_delivery_date_js = getJavascriptDateFromCSharpDate(cotacao.closest_delivery_date);

            let statusEntrega = this.statusEntrega(cotacao.closest_delivery_date_js, cotacao.com_nota_sem_inconsistencia, cotacao.status);
            cotacao.class_name = statusEntrega.classe;
            cotacao.status_entrega = statusEntrega.status;
            cotacao.order = statusEntrega.order != null ? statusEntrega.order : 99;
        }
    }

    carregarTela()
    {
        this.ngxLoader.startLoader('loader-principal');

        this.quotations = [];
        this.getQuotations(0);
    }

    get searchTerm(): string
    {
        return this._searchTerm;
    }

    set searchTerm(value: string)
    {
        this._searchTerm = value;
        this.filteredQuotations = this.filterQuotations();
        this.sortQuotations();
    }

    filterQuotations()
    {
        if (!this._searchTerm || !this.quotations)
            return this.quotations;

        var term: string = this._searchTerm.toLowerCase().replace('-', '');

        var cotacoes = this.quotations.filter(quotation =>
            quotation.transaction_id.toString().indexOf(term) !== -1 ||
            quotation.quotation_id.toString().indexOf(term) !== -1 ||
            quotation.order_id.toString().indexOf(term) !== -1 ||
            (quotation.status_entrega && quotation.status_entrega.toLowerCase().indexOf(term) !== -1) ||
            (quotation.vehicle != undefined && quotation.vehicle.replace('-', '').toLowerCase().indexOf(term) !== -1) ||
            (quotation.brand != undefined && quotation.brand.toLowerCase().indexOf(term) !== -1) ||
            (quotation.repairer_name != undefined && quotation.repairer_name.toLowerCase().indexOf(term) !== -1)
        );

        return cotacoes;
    }

    getQuotations(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.getQuotations_semLoading(nAttempts);
    }

    getQuotations_semLoading(nAttempts: number) {
        this.getDashboard = this.dashboardService.getDashboard().subscribe({
            next: (response: ApiResponseModel<{ quotations: DashboardQuotationModel[], lastInventoryUpdateDate: LastInventoryUpdateDateModel }>) => {
                if (response && response.success) {
                    var quotations = response.result.quotations;
                    this.lastInventoryUpdateDate = response.result.lastInventoryUpdateDate;

                    quotations.forEach(cotacao => { this.atualizarDadosCotacao(cotacao); });

                    for (let i = 0; i < quotations.length; i++)
                    {
                        let cotacao = this.quotations.find(c => {
                            if (quotations[i].status === 0) {
                                return c.transaction_id == quotations[i].transaction_id && c.coluna === quotations[i].coluna;
                            }
                            return c.order_id == quotations[i].order_id && c.coluna === quotations[i].coluna;
                        });

                        if (isEmpty(cotacao))
                        {
                            this.quotations.push(quotations[i]);
                        }
                    }

                    this.updateFilteredQuotations();
                }

                try
                {
                    this.ngxLoader.stopLoader('loader-principal');
                } catch (e)
                { }
            },
            error: error =>
            {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    try
                    {
                        this.ngxLoader.stopLoader('loader-principal');
                    } catch (e)
                    { }
                    return;
                }

                this.getQuotations_semLoading(++nAttempts);
            }
        });
    }

    goToConfirmarEntrega(cotacao: any)
    {
        this.router.navigate(['/vendedor/visao-geral/detalhe/' + cotacao.order_id]);
    }

    goToConfirmarPedido(cotacao: any)
    {
        this.router.navigate(['/vendedor/visao-geral/confirmar-pedido/' + cotacao.order_id]);
    }

    goToResponder(cotacao: any) {
        this.router.navigate(['/vendedor/visao-geral/responder/' + cotacao.transaction_id]);
    }

    private inicializarTela(ctr: number)
    {
        if (ctr > 10)
            return;

        var user_id = '';
        this.activatedRoute.queryParams.subscribe(params => { user_id = params['user_id']; });

        var isLogged = this.accountService.checkIfIsLogged();

        if ((isLogged && (isEmpty(user_id) || this.accountService.usuarioLogado.id.toString() === user_id)) || window.self === window.top) // Se não estiver em um iframe
            this.carregarTela();
        else
            setTimeout(() => this.inicializarTela(++ctr), 1000);
    }

    sortQuotations()
    {
        if (this.filteredQuotations === null || this.filteredQuotations === undefined)
            return;

        this.column01Quotations = this.filteredQuotations.filter(q => q.coluna === 0);

        this.column02Quotations = this.filteredQuotations.filter(q => q.coluna === 1 || q.coluna === 2);
        this.column02Quotations.sort(sort_by([{ name: 'coluna', reverse: true }, { name: 'closest_delivery_date_js' }]));

        this.column03Quotations = this.filteredQuotations.filter(q => q.coluna === 3);
        this.column03Quotations.sort(sort_by([{ name: 'order' }, { name: 'closest_delivery_date_js' }]));

        this.column04Quotations = this.filteredQuotations.filter(q => q.coluna === 4);
        this.column04Quotations.sort(sort_by([{ name: 'closest_delivery_date_js' }]));
    }

    statusEntrega(delivery_date, com_nota_sem_inconsistencia: boolean, status: number): any
    {
        switch(status) {
            case StatusIdEnum.EmAnalise:
                return { status: this.globals.translate('seller.dashboard.colunas.titulos.em-analise'), classe: "label-warning" };
            case StatusIdEnum.Pedido:
                return { status: this.globals.translate('seller.dashboard.colunas.titulos.confirmacao'), classe: "label-yellow" };
            case StatusIdEnum.AguardandoConfirmacaoDevolucao:
                return { status: this.globals.translate('seller.dashboard.colunas.titulos.confirmacao-devolucao'), classe: "label-yellow" };
            case StatusIdEnum.AguardandoConfirmacaoTroca:
                return { status: this.globals.translate('seller.dashboard.colunas.titulos.confirmacao-troca'), classe: "label-yellow" };
            case StatusIdEnum.Entregue:
                return { status: '', classe: "" };
        }

        delivery_date.setHours(0, 0, 0, 0);

        var hoje = new Date();
        hoje.setHours(0, 0, 0, 0);
        var amanha = new Date(new Date().getTime() + (24 * 60 * 60 * 1000));
        amanha.setHours(0, 0, 0, 0);

        if (delivery_date.getTime() < hoje.getTime())
        {
            const hasNFTitle = com_nota_sem_inconsistencia ? '-purple' : '';
            const hasNFText = com_nota_sem_inconsistencia ? { order: 2, status: 'despachado' } : { order: 1, status: 'atrasado' };
            return { order: hasNFText.order, status: this.globals.translate(`buyer.visao-geral.colunas.titulos.${hasNFText.status}`), classe: `label-danger${hasNFTitle}` };
        }

        if (delivery_date.getTime() == hoje.getTime())
            return { status: this.globals.translate('seller.dashboard.colunas.titulos.entrega-hoje'), classe: "label-warning" };

        if (delivery_date.getTime() == amanha.getTime())
            return { status: this.globals.translate('seller.dashboard.colunas.titulos.entrega-amanha'), classe: "label-warning" };

        if (delivery_date.getTime() > amanha.getTime())
            return { status: this.globals.translate('seller.dashboard.colunas.titulos.aguardando-entrega'), classe: "label-success" };
    }

    updateFilteredQuotations()
    {
        this.filteredQuotations = this.filterQuotations();
        this.sortQuotations();
    }

    uploadNotaFiscalAbrir($event)
    {
        $($event.target).next().click()
    }

    async uploadNotaFiscalGravar($event, cotacao) {
        let nfComponent = this.nfComponent;
        this.dashboardService.incluirNF($event, cotacao, nfComponent, null, null);
    }

    private matchQuotationAndOrder(quotation, quotationToCompare) {
        if(quotation.order_id) {
            return quotationToCompare.order_id === quotation.order_id;
        }

        return quotationToCompare.quotation_id === quotation.quotation_id;
    }

    signalRAtualizarCotacao(cotacao) {
        let index = this.quotations.findIndex(q => this.matchQuotationAndOrder(cotacao, q));

        if(cotacao.status_id <= 0)
            return;

        if (isEmpty(index) || index < 0) {
            if (cotacao.is_active && cotacao.status <= StatusIdEnum.AguardandoDevolucao) // Somente inclui novamente se estiver ativa
                this.quotations.push(cotacao);
        }
        else
            deepSetObj(this.quotations[index], cotacao);

        this.quotations.forEach(cotacao => { this.atualizarDadosCotacao(cotacao); });
        this.updateFilteredQuotations();
    }

    signalRAtualizarCotacaoAtualizado(cotacao)
    {
        let index = this.quotations.findIndex(q => q.quotation_id === cotacao.quotation_id);

        if(cotacao.status_id <= 0) return

        if(cotacao.is_active == false && index >= 0) {
            this.quotations.splice(index, 1);
            this.updateFilteredQuotations()
            return;
        }

        this.atualizarDadosCotacao(cotacao);

        this.inserirCotacaoNaLista(this.quotations, cotacao, index, () => this.updateFilteredQuotations());
    }

    signalRExcluirCotacao(cotacao)
    {
        let index = (!cotacao.is_active) ?
            this.quotations.findIndex(q => q.quotation_id === cotacao.quotation_id) :
            this.quotations.findIndex(q => q.quotation_id === cotacao.quotation_id && q.coluna === cotacao.coluna);

        if (!isEmpty(index) && index >= 0)
        {
            this.quotations.splice(index, 1);
            this.updateFilteredQuotations();
        }
    }


    registerObservables() {
        this.subscriptionAtualizarDashboardVendedorEvent = this.sellerSignalRService.AtualizarDashboardVendedorEvent.subscribe(data => {
            if (this.sellerSignalRService.enableSignalRLog)
                console.log('SellerDashboardComponent.AtualizarDashboardVendedorEvent', data);

            if (isEmpty(data)) {
                this.quotations = [];
                this.getQuotations_semLoading(0);
                return;
            }

            let cotacao = JSON.parse(data);

            if (cotacao.is_active)
                this.signalRAtualizarCotacao(cotacao);
            else
                this.signalRExcluirCotacao(cotacao);
        });
    }
}
