<div class="produtos-commerce">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="true"
        [hasSearchButton]="isList"
        [hasMinCharacters]="false"
        [searchInput]="searchTerm"
        [searchPlaceholder]="isList ? ('seller.commerce.products.header.search-placeholder' | translate) : null"
        [title]="('seller.commerce.products.header.titles.' + (isList ? 'list' : selectedProductToEdit ? 'edition' : 'creation')) | translate"
        (onSearch)="searchTerm = $event"
    >
        @if (isList) {
            <button customButton styleType="secondary" iconName="edit" [label]="'seller.commerce.products.header.buttons.edit-all' | translate" [isLoading]="false" (click)="editAllModal.open()"></button>
            <button customButton styleType="secondary" iconName="plus" [label]="'seller.commerce.products.header.buttons.new' | translate" [isLoading]="false" (click)="openProductCreation()"></button>
        } @else {
            <button customButton styleType="secondary" iconName="arrow-left" [label]="'seller.commerce.products.header.buttons.back' | translate" [isLoading]="false" (click)="goBackToList()"></button>
        }
    </app-header>

    @if (isList) {
        <app-commerce-products-list [products]="products" [totalProducts]="totalProducts" [pageNumberController]="actualPage" (editProduct)="openProductEdition($event)" (pageChange)="getByPage($event)"></app-commerce-products-list>
    } @else {
        <app-commerce-product-manage [product]="selectedProductToEdit" (goBackToList)="goBackToList()" (saveProduct)="handleProductSave($event)"></app-commerce-product-manage>
    }
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>

<app-commerce-edit-all #templateEditAll [productsCount]="totalProducts" (editProducts)="editAll($event.cashback, $event.discount)"></app-commerce-edit-all>
