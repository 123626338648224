import { Component, OnInit, ViewChild } from '@angular/core';
import { SellerCommerceEditAllComponent } from './_components/edit-all/edit-all.component';
import { SellerCommerceService } from './produtos-commerce.service';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { GetByPageResponse } from './_models/getByPageResponse';
import { Product } from './_models/product';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'app/_services/notification.service';
import { Globals } from 'app/_models/globals';

declare const isEmpty: any;

@Component({ selector: 'app-produtos-commerce', templateUrl: './produtos-commerce.component.html', styleUrls: ['./produtos-commerce.component.scss'] })
export class SellerCommerceComponent {
    _searchString: string = '';
    isList: boolean = true;
    isLoading: boolean = false;
    totalProducts: number;
    selectedProductToEdit = null;
    actualPage: number = 0;

    @ViewChild('templateEditAll') public editAllModal: SellerCommerceEditAllComponent;

    products: Product[];

    constructor(private sellerCommerceService: SellerCommerceService, private route: ActivatedRoute, private notificationService: NotificationService, private ngxLoader: NgxUiLoaderService, private globals: Globals) {
    }

    ngOnInit() {
        let urlSegments = this.route.snapshot.url;
        let param_new_product = urlSegments.some(segment => segment.path === 'new');
        let param_id = this.route.snapshot.params.id;
        this.getByPage(0, param_new_product, param_id)
    }

    createProduct(product: Product) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.createProduct(product).subscribe({
            next: (response: ApiResponseModel<Product>) => {
                this.ngxLoader.stopLoader('loader-principal');

                if (this.actualPage == 0) {
                    this.products.unshift(response.result);
                    this.updateProductTotal(this.products[0]);
                    this.totalProducts++;
                } else {
                    this.getByPage(0);
                }

                this.goBackToList();
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.isLoading = false;
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.messages.error.creation'));
            }
        })
    }

    editAll(cashback: number, discount: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.editAll(cashback, discount).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                this.ngxLoader.stopLoader('loader-principal');

                if (response.success && response.result) {
                    this.products.forEach((product) => {
                        product.discount = discount;
                        product.cashback = cashback;
                        this.updateProductTotal(product);
                    });

                    this.editAllModal.close();
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.messages.error.edit-all'));
            }
        })
    }

    getByPage(pageNumber: number, isNewProduct: boolean = false, productId?: string) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.getByPage(pageNumber, this._searchString.toLowerCase()).subscribe({
            next: (response: ApiResponseModel<GetByPageResponse>) => {
                this.actualPage = pageNumber;
                this.totalProducts = response.result.totalProducts;
                this.products = response.result.products;
                this.products.forEach(product => this.updateProductTotal(product));

                if (isNewProduct) {
                    this.openProductCreation();
                } else if (!isEmpty(productId)) {
                    let product = this.products.find(product => product.productId === productId);
                    this.openProductEdition(product);
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.messages.error.list'));
            }
        });
    }

    goBackToList() {
        this.isList = true;
        this.selectedProductToEdit = null;
        history.pushState({}, '', '/vendedor/commerce');
    }

    handleProductSave(product: Product) {
        if (product.productId)
            return this.saveProduct(product);

        return this.createProduct(product);
    }

    openProductCreation() {
        this.isList = false;
        history.pushState({}, '', '/vendedor/commerce/new');
    }

    openProductEdition(product: Product) {
        this.selectedProductToEdit = product;
        history.pushState({}, '', '/vendedor/commerce/edit/' + product.productId);
        this.isList = false;
    }

    saveProduct(product: Product) {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerCommerceService.editProduct(product).subscribe({
            next: (response: ApiResponseModel<Product>) => {
                this.ngxLoader.stopLoader('loader-principal');

                let index = this.products.findIndex(p => p.productId == response.result.productId);
                this.products[index] = response.result;
                this.updateProductTotal(this.products[index]);
                this.goBackToList();
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.isLoading = false;
                this.notificationService.showErrorToastr(this.globals.translate('seller.commerce.messages.error.edit'));
            }
        })
    }

    updateProductTotal(product: Product) {
        if (!product.discount) {
            product.priceWithDiscount = null;
            return;
        }

        product.priceWithDiscount = product.price - (product.price * product.discount/100);
    }

    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.getByPage(0);
    }
}
