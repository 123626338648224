<ng-template #templateEditAll>
    <div class="modal-mass-edition">
        <div class="modal-header">
            <div class="d-flex flex-column">
                <h4 class="modal-title">{{ 'seller.commerce.products.edit-all.title' | translate }}</h4>
                <span class="modal-subtitle">
                    <span>{{ 'seller.commerce.products.edit-all.subtitle' | translate }}</span>
                </span>
            </div>
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="close();"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="editionForm">
                <div>
                    <label for="discount">{{ 'seller.commerce.products.edit-all.form.discount.label' | translate }}</label>
                    <div class="input-with-icon discount">
                        <input type="text" class="form-control" name="discount" formControlName="discount" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="4"/>
                    </div>
                    <span class="discount">
                        <i-feather name="check" class="check"></i-feather>
                        {{ editionForm.value.discount | number }}% {{ 'seller.commerce.products.edit-all.form.discount.info' | translate }}
                    </span>
                </div>
                <div>
                    <label for="cashback">{{ 'seller.commerce.products.edit-all.form.cashback.label' | translate }}</label>
                    <div class="input-with-icon cashback">
                        <input type="text" class="form-control" name="cashback" formControlName="cashback" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="4"/>
                    </div>
                    <span class="cashback">
                        <i-feather name="check" class="check"></i-feather>
                        {{ editionForm.value.cashback | number }}% {{ 'seller.commerce.products.edit-all.form.cashback.info' | translate }}
                    </span>
                </div>
            </form>
            <p class="products text-end"><b>{{productsCount}}</b> {{ 'seller.commerce.products.edit-all.product-count' | translate }}</p>
        </div>
        <div class="modal-footer">
            <button customButton styleType="primary" [outline]="true" iconName="x" [label]="'elemento.generico-botao.cancelar' | translate" [isLoading]="false" (click)="close()"></button>
            <button customButton styleType="secondary" [iconName]="!isLoading ? 'check' : null" [label]="'seller.commerce.products.edit-all.confirm-edition' | translate" [isLoading]="isLoading" (click)="save()"></button>
        </div>
    </div>
</ng-template>
