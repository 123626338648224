import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class SellerPedidosService extends BaseService
{
    private _domain: string = '/seller/orders';

    constructor(private accountService: AccountService, private config: SettingsService)
    {
        super();
    }

    public getAll(filtro: string, dias: number)
    {
        if (isEmpty(this.usuarioLogado))
        {
            this.accountService.logout();
            return EMPTY;
        }

        const seller_company_id = this.usuarioLogado.user_company_id;
        const query = { seller_company_id, filtro, dias };

        return this.config.executeGet({ url: `${this._domain}?sellerCompanyId=${query.seller_company_id}&filtro=${query.filtro}&dias=${query.dias}` });
    }
}
