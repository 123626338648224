import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from "ngx-ui-loader";

import { CancelReasons, CancelReasonRelatedId } from 'app/_models/cancelReasons';
import { Globals } from "app/_models/globals";
import { StatusIdEnum, isFinalStatus, isOldStatusConfirmado } from 'app/_models/statusId';
import { Transportadora } from "app/_models/transportadora";

import { AccountService } from "app/account/account.service";
import { ImageDisplayService } from 'app/_componentes/image-display/image-display.service';
import { NotaFiscalService } from 'app/_componentes/notafiscal/notafiscal.service';
import { NotificationService } from "app/_services/notification.service";
import { SellerDadosCotacaoService } from "./dados-cotacao.service";
import { SellerService } from "../../seller.service";
import { SellerDashboardService } from '../../dashboard/dashboard.service';
import { SettingsService } from 'app/_services/settings.service';
import { ApiResponseModel } from "app/_models/apiResponseModel";
import { SellerDashboardGetRejectOrderReasonsResponseModel } from "app/seller/_models/sellerDashboardGetRejectOrderReasonsResponseModel";
import { NotaFiscalComponent } from "app/_componentes/notafiscal/notafiscal.component";

declare const $: any;

@Component({ selector: 'dados-cotacao', templateUrl: './dados-cotacao.component.html', styleUrls: ['./dados-cotacao.component.scss'] })
export class SellerDadosCotacaoComponent
{
    @Input() pedido: any;
    @Input() transportadoras: Transportadora[] = [];
    @Input() voltarHome: boolean;
    @Output() atualizarEvent = new EventEmitter();
    @ViewChild('templateFotos') templateFotos: TemplateRef<any>;
    modalFPRef: BsModalRef;
    @ViewChild('templateNotasFiscais') templateNotasFiscais: TemplateRef<any>;
    modalNFRef: BsModalRef;
    @ViewChild(NotaFiscalComponent) nfComponent: NotaFiscalComponent;

    pdfSrc: string;
    visaoPDF: boolean;

    motivosRejeitar: CancelReasons[] = [];
    reasonOutros = false;

    @ViewChild('templateRejeitarPeca') templateRejeitarPeca: TemplateRef<any>;
    modalRejeitarPecaREF: BsModalRef;

    get StatusIdEnum(): typeof StatusIdEnum {
        return StatusIdEnum;
    }

    constructor(public accountService: AccountService, private nfService: NotaFiscalService, private notificationService: NotificationService, private dashboardService: SellerDashboardService,
        private dadosCotacaoService: SellerDadosCotacaoService, private imageDisplayService: ImageDisplayService, public sellerService: SellerService, public settingService: SettingsService, private globals: Globals, private ngxLoader: NgxUiLoaderService,
        private router: Router, private modalService: BsModalService) { }

    acaoSelecionarPeca(peca, $event)
    {
        peca.acao = $($event.target).is(":checked");
        this.atualizarPedidoAlguemSelecionado();
    }

    acaoSelecionarPeca_Todas($event)
    {
        var acao = $($event.target).is(":checked");
        this.pedido.pecas.filter(p => isOldStatusConfirmado(p.status)).map(p => p.acao = acao);
        this.atualizarPedidoAlguemSelecionado();
    }

    atualizarPedidoAlguemSelecionado()
    {
        this.pedido.alguemSelecionado = this.pedido.pecas.filter(p => p.acao && isOldStatusConfirmado(p.status)).length > 0;
    }

    carregarMotivosRejeitar(nAttempts: number, quotation_id : number) {
        this.ngxLoader.startLoader('loader-rejeitar');

        this.dadosCotacaoService.getRejectReasons(CancelReasonRelatedId.PecaRejeitadaPeloVendedor).subscribe({
            next: (response: ApiResponseModel<SellerDashboardGetRejectOrderReasonsResponseModel>) => {
                this.motivosRejeitar = response.result.cancelReasons;
                this.ngxLoader.stopLoader('loader-rejeitar');
            },
            error: error =>
            {
                if (error.status === 404)
                {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: quotation_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.ngxLoader.stopLoader('loader-rejeitar');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarMotivosRejeitar(++nAttempts, quotation_id);
            }
        })
    }

    async confirmarEntrega(nAttempts)
    {
        if (!(await this.notificationService.showConfirmationDialog('seller.dashboard.detalhes.confirmar-entrega.confirm')))
            return;

        this.ngxLoader.startLoader('loader-principal');

        var pecas_ids = this.pedido.pecas.filter(p => p.acao && isOldStatusConfirmado(p.status)).map(p => p.item_answer_id);

        this.dadosCotacaoService.confirmDelivery(this.pedido.quotation_id, pecas_ids)
            .subscribe(
                response =>
                {
                    var status_peca = this.globals.translate('seller.dashboard.detalhes.confirmar-entrega.status');

                    // peça pedido entrega confirmada pelo vendedor
                    this.pedido.pecas.filter(p => pecas_ids.includes(p.item_answer_id)).map(p => { p.status = StatusIdEnum.Entregue; p.status_peca = status_peca });

                    this.notificationService.showSuccessToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.sucesso.confirmar-entrega.corpo'));
                    let todasPecasFinalizadas = (this.pedido.pecas.length === this.pedido.pecas.filter(p => isFinalStatus(p.status)).length);

                    this.pedido = this.sellerService.atualizarTotais(this.pedido);
                    this.pedido.alguemParaAcao = !todasPecasFinalizadas;

                    if (todasPecasFinalizadas && this.voltarHome)
                        this.sellerService.voltar(null);
                    else
                        this.ngxLoader.stopLoader('loader-principal');
                },
                error =>
                {
                    if (error.status === 404 && this.voltarHome)
                    {
                        this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: this.pedido.id });
                        return;
                    }

                    nAttempts = nAttempts || 1;
                    console.log(error, nAttempts);

                    if (nAttempts >= 5)
                    {
                        this.ngxLoader.stopLoader('loader-principal');
                        this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.confirmar-entrega.corpo'));
                        return;
                    }

                    this.confirmarEntrega(++nAttempts);
                });
    }

    goToInconsistencias()
    {
        this.router.navigateByUrl('/vendedor/notas-fiscais/' + this.pedido.quotation_id, { state: { pedido: this.pedido, transportadoras: this.transportadoras } });
    }

    modal_rejeitar_abrir() {
        let status = this.pedido.pecas.filter(p => !isFinalStatus(p.status))[0]?.status

        if(status == null) return

        $('textarea[name="comment"]').val('');
        this.reasonOutros = false;
        $('select[name="reason"]').val([]);

        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };
        this.modalRejeitarPecaREF = this.modalService.show(this.templateRejeitarPeca, options);

        this.carregarMotivosRejeitar(0, this.pedido.quotation_id);
    }

    modal_rejeitar_fechar() {
        this.modalRejeitarPecaREF.hide();
    }

    modal_fotos_abrir()
    {
        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };
        this.modalFPRef = this.modalService.show(this.templateFotos, options);
    }

    modal_fotos_fechar()
    {
        this.modalFPRef.hide();
    }

    modal_notas_fiscais_abrir()
    {
        this.pedido.pedidoNFs = [];
        this.dashboardService.montarNFPedido(this.pedido);

        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };
        this.visaoPDF = false;
        this.modalNFRef = this.modalService.show(this.templateNotasFiscais, options);
    }

    modal_notas_fiscais_fechar()
    {
        this.visaoPDF = false;
        this.modalNFRef.hide();
    }

    modal_notas_fiscais_onError(error: any)
    {
        this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.carregamento-nota-fiscal.corpo'));
    }

    modal_notas_fiscais_upload_abrir($event)
    {
        $($event.target).next().click()
    }

    async rejeitarEntrega(nAttempts: number) {
        var notes = $('textarea[name="comment"]').val().trim();

        if (this.reasonOutros && notes.length === 0) {
            await this.notificationService.showWarning('seller.dashboard.rejeitar-peca.notes-erro.corpo', 'seller.dashboard.rejeitar-peca.notes-erro.titulo');
            return;
        }

        if (!(await this.notificationService.showConfirmationDialog('seller.dashboard.detalhes.rejeitar.confirm')))
            return;

        this.ngxLoader.startLoader('loader-rejeitar');

        var reason_id = Number($('select[name="reason"]').val().split(' - ')[0]);

        var pecas_ids = this.pedido.pecas.filter(p => p.acao && isOldStatusConfirmado(p.status)).map(p => p.item_answer_id);

        this.dadosCotacaoService.rejectItens(this.pedido.order_id, pecas_ids, reason_id, notes.length > 0 ? notes : null).subscribe({
            next: () => {
                this.atualizarEvent.emit({ order_id: this.pedido.order_id });

                this.notificationService.showSuccessToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.sucesso.rejeitar-entrega.corpo'));
                var todasPecasFinalizadas = (this.pedido.pecas.length === this.pedido.pecas.filter(p => isFinalStatus(p.status)).length);

                this.ngxLoader.stopLoader('loader-rejeitar');
                this.modal_rejeitar_fechar()

                if (todasPecasFinalizadas && this.voltarHome)
                    this.sellerService.voltar(null);
            },
            error: error => {
                if (error.status === 404 && this.voltarHome) {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: this.pedido.id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-rejeitar');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.rejeitar-entrega.corpo'));
                    return;
                }

                this.rejeitarEntrega(++nAttempts);
            }
        });
    }

    public updateTextArea($event) {
        $('textarea[name="comment"]').val('');

        if($event.target.value.includes('Reason_Outros')) {
            this.reasonOutros = true;
        } else {
            this.reasonOutros = false;
        }
    }

    zoomImage(imageSrc) {
        this.imageDisplayService.setImage(imageSrc);
    }
}
