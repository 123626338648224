import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router, ActivatedRoute } from "@angular/router";

import { Globals } from "../../_models/globals";
import { Transportadora } from "../../_models/transportadora";

import { NotificationService } from "../../_services/notification.service";
import { SellerService } from "../seller.service";

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { SellerDadosCotacaoComponent } from '../_componentes/dados-cotacao/dados-cotacao.component';
import { SellerOrdersGetResponseModel } from "./_models/sellerOrdersGetResponseModel";

declare const $: any;

@Component({ selector: 'app-detalhe', templateUrl: './detalhe.component.html', styleUrls: ['./detalhe.component.scss'] })
export class SellerVisaoGeralDetalheComponent implements OnInit
{
    isOnError: boolean;
    pedido: any;
    order_id: number;
    transportadoras: Transportadora[] = [];
    @ViewChild(SellerDadosCotacaoComponent) child: SellerDadosCotacaoComponent;

    constructor(private notificationService: NotificationService, public sellerService: SellerService, private globals: Globals, private route: ActivatedRoute, private carouselConfig: NgbCarouselConfig,
        private ngxLoader: NgxUiLoaderService, private router: Router)
    // tslint:disable-next-line: one-line
    {
        this.carouselConfig.interval = 200000;
    }

    ngOnInit()
    {
        this.pedido = {};

        // Obtém o id do pedido passado por parâmetro
        var param_id = this.route.snapshot.params.id;
        var isValid = /^\d+$/.test(param_id);
        this.isOnError = !isValid;

        if (isValid)
        {
            this.order_id = parseInt(param_id.toString());
            // tslint:disable-next-line: radix
            this.carregarDetalhes(this.order_id, 0);
        }

        window.onafterprint = function ()
        {
            $('html,body').css('height', 'auto')
        }
    }

    ngAfterViewChecked()
    {
        // Caso a tela esteja com erro, volta para a home
        if (this.isOnError)
            this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.sem-codigo', parametro: '' });
    }

    abrirNotasFiscais()
    {
        this.child.goToInconsistencias();
    }

    atualizarEvent($event)
    {
        this.carregarDetalhes($event.order_id, 0);
    }

    carregarDetalhes(order_id: number, nAttempts: number)
    {
        this.ngxLoader.startLoader('loader-principal');

        this.sellerService.getOrderDetail(order_id).subscribe({
            next: (response: ApiResponseModel<SellerOrdersGetResponseModel>) =>
            {
                if (response != null)
                {
                    this.pedido = response.result.pedido;

                    this.sellerService.finalizarObtencaoPedido(this.pedido, response.result.fotos, order_id);

                    this.transportadoras = response.result.transportadoras;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error =>
            {
                if (error.status === 404)
                {
                    this.sellerService.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: order_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.detalhes.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarDetalhes(order_id, ++nAttempts);
            }
        });
    }

    imprimir($event)
    {
        $event.preventDefault();
        this.sellerService.imprimir(this, { vehicle: $('.title-section')[0], header: $('.painelcabecalho')[0], subheader: $('.info-cotacao')[0], footer: $('.card-footer')[0], printView: $('#printView') });
    }
}
