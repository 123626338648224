import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Product } from '../../_models/product';

@Component({ selector: 'app-commerce-products-list', templateUrl: './products-list.component.html', styleUrls: ['./products-list.component.scss'] })
export class SellerCommerceProductsListComponent {
    @Input() pageNumberController: number;
    @Input() products: Product[];
    @Input() totalProducts: number;
    @Output() editProduct = new EventEmitter<any>();
    @Output() pageChange = new EventEmitter<number>();

    constructor() { }

    selectProductToEdit(product: any) {
        this.editProduct.emit(product);
    }

    changePage($event: PageEvent) {
        this.pageChange.emit($event.pageIndex);
    }
}
