export interface Image {
    imageFile?: string,
    imageName?: string,
    contentType?: string,
    isSelected: boolean,
    order?: number
}

export function emptyImageFactory(index: number): Image {
    return { imageFile: null, imageName: null, isSelected: false, order: index } as Image;
}

export function emptyImageArrayFactory(quantity: number): Image[] {
    var response: Image[] = [];

    for (let index = 0; index < quantity; index++) {
        response.push(emptyImageFactory(index));
    }

    return response;
}
