export interface Product {
    productId: string,
    partNumber: string,
    partName: string,
    description?: string,
    categoryId: string,
    category?: string,
    price: number,
    discount: number,
    cashback: number,
    images: ProductImage[],
    isActive: boolean,
    holdingId: number,
    brandId: number,
    priceWithDiscount?: number
}

export function productFactory(productId: string, partNumber: string, title: string, description: string, categoryId: string, category: string, price: number, discount: number, cashback: number, images: ProductImage[], isActive: boolean, holdingId: number, brandId: number) {
    return {
        productId: productId,
        partNumber: partNumber,
        partName: title,
        description: description,
        categoryId: categoryId,
        category: category,
        price: price,
        discount: discount,
        cashback: cashback,
        images: images,
        isActive: isActive,
        holdingId: holdingId,
        brandId: brandId
    } as Product;
}

export interface ProductImage {
    contentType: string,
    imageName: string,
    imageB64: string,
    url?: string,
    order?: number
}
