<div class="visao-geral-confirmar" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [brandIconName]="pedido?.marca"
        [title]="pedido?.veiculo"
        (onGoBack)="sellerService.voltar(null)"
    >
        <button class="btn btn-light imprimir" (click)="imprimir($event)"><i class="fa fa-print"></i>&nbsp;{{ 'seller.dashboard.detalhes.botao.imprimir' | translate}}</button>
    </app-header>

    <div class="col-md-12 area-confirmar {{ accountService.obterCssInIframe() }}">
        <div class="row only-print">
            {{pedido?.marca}} - {{pedido?.veiculo}}
        </div>
        <ng-scrollbar height="auto" class="{{ accountService.obterCssInIframe() }}">
            <div class="row" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
                <div class="col-md-12">
                    <div id="orcamento-solicitado" class="card card-primary orcamento-solicitado">
                        <div class="print-vendedor card-header painelcabecalho">
                            <b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.solicitado-pedido' | translate}}</b>
                            <span class="namecompr">{{pedido?.comprador_empresa}}</span> - <small><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cod-orcamento' | translate}}:</b>  #{{pedido?.order_id}}</small>
                            <br class="only-print" />
                            @if (pedido?.had_cashback) {
                                <span class="cashback text-success"><i class="fa fa-usd"></i> {{ 'seller.dashboard.detalhes.informacoes.cabecalho.cashback-pedido' | translate}} <i class="fa fa-usd"></i></span>
                            }
                        </div>
                        <div class="card-body font12">
                            <div class="dados-cotacao col-md-12">
                                <div class="col-md-6">
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cnpj' | translate}}:</b> {{pedido?.repairer_cnpj_formatado}}</span>
                                    <br />
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.telefone' | translate}}:</b> {{pedido?.buyer_phone_radio}}</span>
                                    <br />
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.endereco' | translate}}:</b> {{pedido?.repairer_address}}</span>
                                </div>
                                @if (pedido && pedido.veiculo) {
                                    <div class="col-md-6">
                                        @if (!settingService.isMercadoPartsfy(pedido.veiculo)) {
                                            <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.chassi' | translate}}:</b> {{pedido?.chassi}}</span>
                                            <br/>
                                        }
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.forma-pagamento' | translate}}:</b> {{pedido?.forma_pagamento}}</span>
                                        <br />
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.data-pedido' | translate}}:</b> {{pedido?.data_pedido}}</span>
                                    </div>
                                }
                            </div>
                            <div class="table-responsive col-md-12 lista-pecas flip-card">
                                <table class="table table-bordered table-hover flip-card-inner" [ngClass]="isOnRejectMode ? 'reject-mode' : 'confirm-mode'">
                                    <thead>
                                        <tr>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.quantidade' | translate}}</th>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.peca' | translate}}</th>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.valor' | translate}}</th>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.desconto' | translate}}</th>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.prazo.label' | translate}} <i class="fa fa-info-circle no-print" tooltip="{{ 'seller.dashboard.detalhes.tabela.prazo.tooltip' | translate}}"></i></th>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.tipo-peca' | translate}}</th>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.status' | translate}}</th>
                                            <th>{{ 'seller.dashboard.detalhes.tabela.total' | translate}}</th>
                                            @if (isOnRejectMode) {
                                                <th>{{ 'seller.dashboard.detalhes.tabela.rejeitar' | translate}}</th>
                                            }
                                            @if (hasDevolucaoTroca) {
                                                <th></th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (peca of pedido?.pecas; track peca) {
                                            <tr>
                                                <td>{{peca.quantidade}}</td>
                                                <td class="ellipsis4" ellipsis>{{peca.peca}}</td>
                                                <td class="text-end">{{peca.valor_unitario | currency:'R$ '}}</td>
                                                <td class="text-end">{{peca.desconto | currency:' '}}%</td>
                                                <td class="text-center">{{peca.prazo_entrega}}</td>
                                                <td>{{peca.tipo_peca}}</td>
                                                <td>
                                                    <i class="circle circle-{{sellerService.obterStatusClassName(peca.status)}} circle-lg text-start icone-plus no-print"></i>
                                                    {{peca.status_peca}}
                                                    @if (peca.status >= StatusIdEnum.CotacaoCanceladaComprador && peca.rejected_information) {
                                                        <i class="fa fa-exclamation-circle text-warning float-end"    tooltip="{{ 'buyer.dados-pedido.itens.pecas.situacao.tooltip-cancelamento' | translate}}: {{peca.rejected_information}}"></i>
                                                    }
                                                </td>
                                                <td class="text-end">{{sellerService.obterTotalPeca(peca) | currency:'R$ '}}</td>
                                                @if (isOnRejectMode || hasDevolucaoTroca) {
                                                    <td>
                                                        @if (peca.status < StatusIdEnum.Confirmado || (peca.status_needs_action && (peca.status === selectedStatusId || selectedStatusId == null)) ) {
                                                            <div class="form-check c-checkbox needsclick checkboxcot">
                                                                <label class="needsclick">
                                                                    <input type="checkbox" class="needsclick" (click)="selecionarPeca(peca, $event)">
                                                                    <span class="fa fa-check"></span>
                                                                </label>
                                                            </div>
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <div class="area-rejeitar text-end">
                                    @if (!hasDevolucaoTroca) {
                                        @if (!isOnRejectMode) {
                                            <p class="label-aberto">
                                                <span>{{ 'seller.dashboard.detalhes.rejeitar.label-aberto' | translate}}</span>
                                                <button class="btn btn-light" (click)="isOnRejectMode = true">{{ 'seller.dashboard.detalhes.rejeitar.botao.aberto' | translate}}</button>
                                            </p>
                                        } @else {
                                            <p class="label-fechado">
                                                <button class="btn btn-light" (click)="rejeitarVoltar()"><i class="fa fa-undo"></i> {{ 'elemento.generico-botao.voltar' | translate }}</button>
                                                <button class="btn btn-outline-danger" [disabled]="!pedido?.alguemSelecionado ? 'disabled' : null" (click)="modal_abrir(0)">{{ 'seller.dashboard.detalhes.rejeitar.botao.rejeitar' | translate}}</button>
                                            </p>
                                        }
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                            <span><b>{{ 'seller.dashboard.detalhes.tabela.rodape.total' | translate}}:</b> {{ pedido?.total_com_frete | currency:'R$ ' }}</span>
                            <br />
                            <small>
                                {{ 'seller.dashboard.detalhes.tabela.rodape.frete.label' | translate}}:
                                {{ pedido?.frete_formatado }}
                                / {{ 'seller.dashboard.detalhes.tabela.rodape.total-pecas' | translate}}: {{ pedido?.total | currency:'R$ ' }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <fotos-pedido [fotos]="pedido?.fotos" [panel_aberto]="true"></fotos-pedido>
        </ng-scrollbar>
    </div>

    @if (!hasDevolucaoTroca) {
        <div class="area-botoes">
            <button class="btn btn-outline-warning" [disabled]="pedido?.statusEmAnalise || isOnRejectMode ? 'disabled' : null" (click)="modal_abrir(1)">{{ 'seller.dashboard.detalhes.botao.em-analise' | translate}}</button>
            &nbsp;
            <button class="btn btn-primary" [disabled]="isOnRejectMode ? 'disabled' : null" (click)="confirmarPedido()">{{ 'seller.dashboard.detalhes.botao.confirmar-pedido' | translate}}</button>
        </div>
    } @else {
        <div class="area-botoes">
            <button class="btn btn-primary" [disabled]="!pedido?.alguemSelecionado ? 'disabled' : null" (click)="abrirModalConfirmarDevolucaoTroca()">
                {{
                    (selectedStatusId == StatusIdEnum.AguardandoConfirmacaoTroca      ? 'seller.dashboard.detalhes.botao.confirmar-troca' :
                     selectedStatusId == StatusIdEnum. AguardandoConfirmacaoDevolucao ? 'seller.dashboard.detalhes.botao.confirmar-devolucao' :
                                                                                        'seller.dashboard.detalhes.botao.confirmar-devolucao-troca') | translate
                }}
            </button>
        </div>
    }
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>

<ng-template #templateColocarAnalise>
    <div class="modal-header">
        <div class="d-flex flex-column">
            <h4 class="modal-title">{{ 'seller.dashboard.colocar-analise.titulo' | translate}}</h4>
            <span class="modal-subtitle">{{ 'seller.dashboard.colocar-analise.subtitulo' | translate}}<span class="dados-veiculo">{{pedido?.veiculo}}</span></span>
        </div>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_fechar(1);"></button>
    </div>
    <div class="modal-body modalscroll">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3 form-group">
                    <label>{{ 'seller.dashboard.colocar-analise.legenda' | translate}}</label>
                    <select (change)="updateTextArea($event)" name="reason" class="form-select m-b">
                        @for (reason of motivosAnalise; track reason) {
                            <option value="{{reason.id}} - {{reason.name}}">{{reason.description}}</option>
                        }
                    </select>
                </div>
            </div>
        </div>
        <div class="row" style="height: 113px;">
            <div class="col-lg-12">
                <div class="mb-3 form-group">
                    <textarea [disabled]="reasonOutros ? null : true" name="comment" rows="4" placeholder="{{'elemento.generico-input.placeholder' | translate}}" class="form-control comment"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" data-dismiss="modal" class="btn btn-light" (click)="modal_fechar(1);">{{ 'seller.dashboard.colocar-analise.botao.cancelar' | translate}}</button>
        <button id="btnSalvar" type="button" class="btn btn-primary" (click)="colocarEmAnalise(0)">{{ 'seller.dashboard.colocar-analise.botao.rejeitar' | translate}}</button>
    </div>
    <ngx-ui-loader [loaderId]="'loader-analise'"></ngx-ui-loader>
</ng-template>

<ng-template #templateRejeitarPeca>
    <div class="modal-header">
        <div class="d-flex flex-column">
            <h4 class="modal-title">{{ 'seller.dashboard.rejeitar-peca.titulo' | translate}}</h4>
            <span class="modal-subtitle">{{ 'seller.dashboard.rejeitar-peca.subtitulo' | translate}}<span class="dados-veiculo">{{pedido?.veiculo}}</span></span>
        </div>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_fechar(0);"></button>
    </div>
    <div class="modal-body modalscroll">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3 form-group">
                    <label>{{ 'seller.dashboard.rejeitar-peca.legenda' | translate}}</label>
                    <select (change)="updateTextArea($event)" name="reason" class="form-select m-b">
                        @for (reason of motivosRejeitar; track reason) {
                            <option value="{{reason.id}} - {{reason.name}}">{{reason.description}}</option>
                        }
                    </select>
                </div>
            </div>
        </div>
        <div class="row" style="height: 113px;">
            <div class="col-lg-12">
                <div class="mb-3 form-group">
                    <textarea [disabled]="reasonOutros ? null : true" name="comment" rows="4" placeholder="{{'elemento.generico-input.placeholder' | translate}}" class="form-control comment"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" data-dismiss="modal" class="btn btn-light" (click)="modal_fechar(0);">{{ 'seller.dashboard.rejeitar-peca.botao.cancelar' | translate}}</button>
        <button id="btnSalvar" type="button" class="btn btn-primary" (click)="rejeitar(0)">{{ 'seller.dashboard.rejeitar-peca.botao.rejeitar' | translate}}</button>
    </div>
    <ngx-ui-loader [loaderId]="'loader-rejeitar'"></ngx-ui-loader>
</ng-template>

<ng-template #templateDataEntrega>
    <div class="modal-header">
        <h4 class="modal-title">{{ getDynamicTitle() }}</h4>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_fechar(2);"></button>
    </div>
    <div class="modal-body modalscroll">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3 form-group">
                    <div class="input-group input-group-sm">
                        <input class="form-control form-control-sm" [(ngModel)]="deliveryDate" placeholder="dd/mm/yyyy" id="ngbDatepicker" name="dp" ngbDatepicker #d="ngbDatepicker" [firstDayOfWeek]="7" [minDate]="calendarMinDate" [maxDate]="calendarMaxDate" [markDisabled]="calendarMarkDisabled">
                        <div class="input-group-text cursor-pointer" (click)="d.toggle()"><i class="fa fa-calendar" aria-hidden="true"></i></div>
                    </div>
                    @if (deliveryDateIsEmpty) {
                        <div class="input-group">
                            <label class="error-date">Campo data não pode ficar vazio</label>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" data-dismiss="modal" class="btn btn-light" (click)="modal_fechar(2);">{{ 'seller.dashboard.detalhes.confirmar-devolucao-troca.botao.cancelar' | translate}}</button>
        <button id="btnSalvar" type="button" class="btn btn-primary" (click)="confirmarDevolucaoTroca()">{{ 'seller.dashboard.detalhes.confirmar-devolucao-troca.botao.confirmar' | translate}}</button>
    </div>
    <ngx-ui-loader [loaderId]="'loader-data-entrega'"></ngx-ui-loader>
</ng-template>

<iframe src="" style=" width: 100%; height: 0px; border: none;" id="printView"></iframe>
