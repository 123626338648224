import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Globals } from '../../_models/globals';
import { HoldingDeliveryTime } from '../../part-manager/_models/holdingDeliveryTime';
import { HoldingPaymentType } from '../../part-manager/_models/holdingPaymentType';
import { HoldingShipping } from '../../part-manager/_models/holdingShipping';

import { AccountService } from '../../account/account.service';
import { NotificationService } from '../../_services/notification.service';
import { SellerConfigurarCondicoesFornecimentoService } from './configurar-condicoes-fornecimento.service';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { SellerCondicaoFornecimentoGetAllResponseModel } from '../_models/sellerCondicaoFornecimentoGetAllResponseModel';
import { SellerCondicaoFornecimentoPostModel } from '../_models/sellerCondicaoFornecimentoPostModel';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';

declare const $: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-configurar-condicoes-fornecimento', templateUrl: './configurar-condicoes-fornecimento.component.html', styleUrls: ['./configurar-condicoes-fornecimento.component.scss'] })
export class SellerConfigurarCondicoesFornecimentoComponent implements OnInit
{
    @ViewChild('stepper') stepper: MatStepper;

    dias_pedido_fabrica: string;

    holding_id: number;

    lista_formas_pagamento: any;
    lista_prazos: any;
    lista_regioes: any;

    formasPagamento: HoldingPaymentType[];
    fretes: HoldingShipping[];
    prazosEntrega: HoldingDeliveryTime[];

    formaPagamentoForm: UntypedFormGroup;
    freteForm: UntypedFormGroup;
    prazoForm: UntypedFormGroup;

    selected_step: number;

    constructor(private accountService: AccountService, private condicaoService: SellerConfigurarCondicoesFornecimentoService, private notificationService: NotificationService, private globals: Globals, private elRef: ElementRef,
        private fb: UntypedFormBuilder, private _location: Location, private ngxLoader: NgxUiLoaderService)
    {
        this.holding_id = this.accountService.obterUsuario().holding_id;

        this.lista_formas_pagamento = [];
        this.lista_prazos = [];
        this.lista_regioes = [];

        this.formasPagamento = [];
        this.fretes = [];
        this.prazosEntrega = [];
    }

    ngOnInit(): void {
        this.formaPagamentoForm = this.fb.group({
            payment_type_id: [0, [Validators.required, Validators.min(1)]],
            start_value: [0, [Validators.min(0)]]
        });

        this.freteForm = this.fb.group({
            regiao: [0, [Validators.required, Validators.min(1)]],
            frete_gratuito: [0, [Validators.min(0)]],
            frete: [0, [Validators.min(0)]],
            frete_conta_comprador: [2, [Validators.required]]
        });

        this.prazoForm = this.fb.group({
            region_id: [0, [Validators.required, Validators.min(1)]],
            delivery_time_id: [0, [Validators.min(0)]]
        });

        this.carregarTela(0);
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');
        this.selected_step = 0;

        this.condicaoService.getAll().subscribe({
            next: (response: ApiResponseModel<SellerCondicaoFornecimentoGetAllResponseModel>) => {
                let deveVoltar = isEmpty(response) || !response.success;

                if (deveVoltar) {
                    this.notificationService.showErrorToastr(this.globals.translate('seller.configurar-condicoes-fornecimento.mensagens.erro.ja-preenchido.corpo'));
                    this._location.back();
                    return;
                }

                this.lista_formas_pagamento = response.result.fornecimentos.formasPagamento;
                this.lista_prazos = response.result.fornecimentos.prazos;
                this.lista_regioes = response.result.fornecimentos.regioes;

                this.formaPagamento_ResetForm();
                this.frete_ResetForm();
                this.prazoEntrega_ResetForm();

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('seller.configurar-condicoes-fornecimento.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.ngxLoader.stopLoader('loader-principal');
                this.carregarTela(++nAttempts);
            }
        });
    }

    frete_Adicionar() {
        let region_id = parseInt(this.freteForm.value.regiao);
        let region_name = this.lista_regioes.find(r => r.region_id === region_id).region_name;
        let frete_conta_comprador = this.freteForm.value.frete_conta_comprador === '1';
        let frete: HoldingShipping = { id: 0, holding_id: this.holding_id, region_id, region_name, min_value: this.freteForm.value.frete_gratuito, shipping_cost: this.freteForm.value.frete, buyer_payer: frete_conta_comprador, is_active: true };
        this.fretes.push(frete);
        this.fretes.sort(sort_by([{ name: 'region_name' }]));

        this.frete_ResetForm();
    }

    frete_MudarContaComprador() {
        if (this.freteForm.value.frete_conta_comprador !== '1')
            return;

        this.freteForm.patchValue({ frete_gratuito: 0, frete: 0 });
    }

    frete_Remover(region_id: number) {
        let frete = this.fretes.find(f => f.region_id === region_id);

        if (isEmpty(frete))
            return;

        var idx = this.fretes.indexOf(frete);
        this.fretes.splice(idx, 1);

        this.frete_ResetForm();
    }

    frete_ResetForm() {
        this.freteForm.reset();
        this.freteForm.patchValue({ frete_gratuito: 0, frete: 0, frete_conta_comprador: 2 });

        let filtradas = this.regioesFiltradas;

        if (filtradas.length > 0)
            this.freteForm.patchValue({ regiao: filtradas[0].region_id });
        else
            this.freteForm.patchValue({ regiao: 0 });
    }

    frete_ValidarFreteGratuito() {
        if (this.freteForm.value.frete <= 0)
            this.freteForm.patchValue({ frete_gratuito: 0 });
    }

    formaPagamento_Adicionar() {
        let payment_type_id = parseInt(this.formaPagamentoForm.value.payment_type_id);
        let payment_type_name = this.lista_formas_pagamento.find(f => f.id === payment_type_id).texto_x;

        let forma_pagamento: HoldingPaymentType = { holding_id: this.holding_id, payment_type_id, payment_type_name, start_value: this.formaPagamentoForm.value.start_value, is_active: true };
        this.formasPagamento.push(forma_pagamento);
        this.formasPagamento.sort(sort_by([{ name: 'payment_type_id' }]));

        this.formaPagamento_ResetForm();
    }

    formaPagamento_Remover(payment_type_id: number) {
        let forma = this.formasPagamento.find(f => f.payment_type_id === payment_type_id);

        if (isEmpty(forma))
            return;

        var idx = this.formasPagamento.indexOf(forma);
        this.formasPagamento.splice(idx, 1);

        this.formaPagamento_ResetForm();
    }

    formaPagamento_ResetForm() {
        this.formaPagamentoForm.reset();
        this.formaPagamentoForm.patchValue({ start_value: 0 });

        let filtradas = this.formasFiltradas;

        if (filtradas.length > 0)
            this.formaPagamentoForm.patchValue({ payment_type_id: filtradas[0].id });
        else
            this.formaPagamentoForm.patchValue({ payment_type_id: 0 });
    }

    prazoEntrega_Adicionar() {
        let region_id = parseInt(this.prazoForm.value.region_id);
        let region_name = this.lista_regioes.find(r => r.region_id === region_id).region_name;

        let delivery_time_id = parseInt(this.prazoForm.value.delivery_time_id);
        let delivery_time_name = this.lista_prazos.find(p => p.delivery_time_id === delivery_time_id).delivery_time_name;

        let prazo: HoldingDeliveryTime = { id: 0, holding_id: this.holding_id, region_id, region_name, delivery_time_id: delivery_time_id, delivery_time_name: delivery_time_name, is_active: true };
        this.prazosEntrega.push(prazo);
        this.prazosEntrega.sort(sort_by([{ name: 'region_name' }]));

        this.prazoEntrega_ResetForm();
    }

    prazoEntrega_Remover(region_id: number) {
        let prazo = this.prazosEntrega.find(f => f.region_id === region_id);

        if (isEmpty(prazo))
            return;

        var idx = this.prazosEntrega.indexOf(prazo);
        this.prazosEntrega.splice(idx, 1);

        this.prazoEntrega_ResetForm();
    }

    prazoEntrega_ResetForm() {
        this.prazoForm.reset();
        this.prazoForm.patchValue({ delivery_time_id: 1 });

        let filtradas = this.regioesFiltradasPrazoEntrega;

        if (filtradas.length > 0)
            this.prazoForm.patchValue({ region_id: filtradas[0].region_id });
        else
            this.prazoForm.patchValue({ region_id: 0 });
    }

    salvar(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        let dias_pedido_fabrica = isEmpty(this.dias_pedido_fabrica) ? 1 : parseInt(this.dias_pedido_fabrica);
        let body: SellerCondicaoFornecimentoPostModel = {
            formasPagamento: this.formasPagamento,
            fretes: this.fretes,
            prazosEntrega: this.prazosEntrega,
            dias_pedido_fabrica: dias_pedido_fabrica
        }

        this.condicaoService.save(body).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (!isEmpty(response) && response.success)
                {
                    this.notificationService.showSuccessToastr(this.globals.translate('seller.configurar-condicoes-fornecimento.mensagens.sucesso.salvar.corpo'));
                    this._location.back();
                }
                else
                    this.notificationService.showErrorToastr(this.globals.translate('seller.configurar-condicoes-fornecimento.mensagens.erro.salvar.corpo'));

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.notificationService.showErrorToastr(this.globals.translate('seller.configurar-condicoes-fornecimento.mensagens.erro.salvar.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.salvar(++nAttempts);
            }
        });
    }

    showPreviousStep() {
        this.stepper.previous();
        this.selected_step--;
    }

    async showNextStep() {
        switch (this.selected_step) {
            case 0:
                if (isEmpty(this.formasPagamento)) {
                    await this.notificationService.showWarning('seller.configurar-condicoes-fornecimento.passos.passo-1.erro.corpo', 'seller.configurar-condicoes-fornecimento.passos.passo-1.erro.titulo');
                    return;
                }
                break;
            case 1:
                if (isEmpty(this.fretes)) {
                    await this.notificationService.showWarning('seller.configurar-condicoes-fornecimento.passos.passo-2.erro.corpo', 'seller.configurar-condicoes-fornecimento.passos.passo-2.erro.titulo');
                    return;
                }
                break;
            case 2:
                if (isEmpty(this.prazosEntrega)) {
                    await this.notificationService.showWarning('seller.configurar-condicoes-fornecimento.passos.passo-3.erro.corpo', 'seller.configurar-condicoes-fornecimento.passos.passo-3.erro.titulo');
                    return;
                }

                if (isEmpty(this.dias_pedido_fabrica)) {
                    await this.notificationService.showWarning('seller.configurar-condicoes-fornecimento.passos.passo-3.erro-dias.corpo', 'seller.configurar-condicoes-fornecimento.passos.passo-3.erro-dias.titulo');
                    return;
                }
                break;
        }

        this.stepper.next();
        this.selected_step++;
    }

    get formasFiltradas() {
        if (isEmpty(this.lista_formas_pagamento))
            return [];

        return this.lista_formas_pagamento.filter(p => this.formasPagamento.filter(f => f.payment_type_id === p.id).length <= 0).sort(sort_by([{ name: 'texto_x' }]));
    }

    get regioesFiltradas() {
        if (isEmpty(this.lista_regioes))
            return [];

        return this.lista_regioes.filter(r => this.fretes.filter(f => f.region_id === r.region_id).length <= 0).sort(sort_by([{ name: 'region_name' }]));
    }

    get regioesFiltradasPrazoEntrega() {
        if (isEmpty(this.lista_regioes))
            return [];

        return this.lista_regioes.filter(r =>
            this.prazosEntrega.filter(f => f.region_id === r.region_id).length <= 0).sort(sort_by([{ name: 'region_name' }]));
    }
}
