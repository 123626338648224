<div class="section-vendedor dashboard" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <div class="row linhavg1">
        <div class="col-md-3"></div>
        <div class="col-md-6 filter-div">
            <div class="col-md-12">
                <ul class="nav navbar-nav col-md-12">
                    <li class="col-md-12">
                        <div class="mb-3 form-group search-box has-feedback mb0">
                            <div>
                                <input type="text" placeholder="{{'seller.dashboard.filtro' | translate}}" class="form-control filter-input" [(ngModel)]="searchTerm" maxlength="50" />
                                <div class="fa icons icon-magnifier form-control-feedback"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-3 ultima-atualizacao">
            @if (lastInventoryUpdateDate != null && lastInventoryUpdateDate.has_integration) {
                <div>
                    @if (lastInventoryUpdateDate.last_inventory_update_formataded) {
                        <span>{{'seller.dashboard.atualizacao-estoque.ultima-atualizacao' | translate}}</span>
                        <span class="small">{{lastInventoryUpdateDate.last_inventory_update_formataded}}</span>
                    } @else {
                        {{'seller.dashboard.atualizacao-estoque.vazio' | translate}}
                    }
                </div>
            }
        </div>
    </div>
    <div class="row linhavg">
        <div class="col-md-3 colunavg">
            <span class="pipe-phase-arrow"></span>
            <div class="cabecalho">
                <div class="row">
                    <div class="col-md-12">
                        <h5><b>{{ 'seller.dashboard.cabecalho.fases.em-cotacao' | translate}}</b></h5>
                        <button type="button" class="btn btn-primary btn-sm">
                            {{column01Quotations.length}} {{ (column01Quotations.length > 1 ? 'seller.dashboard.cabecalho.texto-cotacao.plural' : 'seller.dashboard.cabecalho.texto-cotacao.singular') | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        @if (!menor1024) {
            <div class="col-md-3 colunavg">
                <span class="pipe-phase-arrow"></span>
                <div class="cabecalho">
                    <div class="row">
                        <div class="col-md-12">
                            <h5><b>{{ 'seller.dashboard.cabecalho.fases.aguardando-confirmacao' | translate}}</b></h5>
                            <button type="button" class="btn btn-primary btn-sm">
                                {{column02Quotations.length}} {{ (column02Quotations.length > 1 ? 'seller.dashboard.cabecalho.texto-pedido.plural' : 'seller.dashboard.cabecalho.texto-pedido.singular') | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 colunavg">
                <span class="pipe-phase-arrow"></span>
                <div class="cabecalho">
                    <div class="row">
                        <div class="col-md-12">
                            <h5><b>{{ 'seller.dashboard.cabecalho.fases.aguardando-entrega' | translate}}</b></h5>
                            <button type="button" class="btn btn-primary btn-sm">
                                {{column03Quotations.length}} {{ (column03Quotations.length > 1 ? 'seller.dashboard.cabecalho.texto-pedido.plural' : 'seller.dashboard.cabecalho.texto-pedido.singular') | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="cabecalho">
                    <div class="row">
                        <div class="col-md-12">
                            <h5>
                                <b>{{ 'seller.dashboard.cabecalho.fases.pedidos-entregues' | translate}}</b>
                            </h5>
                            <button type="button" class="btn btn-primary btn-sm">
                                {{column04Quotations.length}} {{ (column04Quotations.length > 1 ? 'seller.dashboard.cabecalho.texto-pedido.plural' : 'seller.dashboard.cabecalho.texto-pedido.singular') | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
    <div class="row seglinha">
        <div class="col-md-3 column1">
            @if (column01Quotations.length > 0) {
                <div class="contentvgac_cotacao">
                    <ng-scrollbar class="{{ accountService.obterCssInIframe() }}">
                        @for (cotacao of column01Quotations; track cotacao) {
                            <div (click)="menor1024 && goToResponder(cotacao)" class="card card-primary card">
                                <div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12 d-flex flex-wrap align-items-center">
                                                <div title="{{cotacao.brand}}" class="float-start media-box-object rounded-circle marca {{cotacao.brand}}"></div>
                                                <label class="truncate mb0">{{cotacao.vehicle}}</label>
                                            </div>
                                        </div>
                                        <div class="linha">
                                            <div class="col">
                                                <i class="fa-car fa car-icon"></i>
                                                <label class="text-normal truncate repairer-name">{{cotacao.repairer_name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer text-start">
                                        <button type="button" class="btn btn-light btn-sm btn-vgcomprar btn-responder" (click)="goToResponder(cotacao)">
                                            {{ 'seller.dashboard.cards.mais-detalhes' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </ng-scrollbar>
                </div>
            } @else {
                <div class="text-center nenhum-encontrado">
                    <img alt="" src="../../../assets/imagens/etiqueta.png" height="156" width="156">
                    <p>{{'seller.dashboard.cards.nenhuma-cotacao-encontrada' | translate}}</p>
                </div>
            }
        </div>
        @if (!menor1024) {
            <div class="col-md-3 column2">
                @if (column02Quotations.length > 0) {
                    <div class="contentvgac_cotacao">
                        <ng-scrollbar class="{{ accountService.obterCssInIframe() }}">
                            @for (cotacao of column02Quotations; track cotacao) {
                                <div class="card card-primary card">
                                    <div>
                                        <div class="card-body">
                                            <div class="statuscard">
                                                <label class="badge paddlab {{cotacao.class_name}}"><i class="fa fa-calendar-o"></i> {{cotacao.status_entrega}}</label>
                                            </div>
                                            @if (cotacao.has_cashback) {
                                                <div class="cashback" tooltip="{{ 'seller.dashboard.cards.cashback-pedido' | translate}}"><b><i class="fa fa-usd text-success"></i></b></div>
                                            }
                                            <div class="row">
                                                <div class="col-md-12 d-flex flex-wrap align-items-center">
                                                    <div title="{{cotacao.brand}}" class="float-start media-box-object rounded-circle marca {{cotacao.brand}}"></div>
                                                    <label class="truncate mb0">{{cotacao.vehicle}}</label>
                                                </div>
                                            </div>
                                            <div class="linha">
                                                <div class="col">
                                                    <i class="fa-car fa car-icon"></i>
                                                    <label class="text-normal truncate repairer-name">{{cotacao.repairer_name}}</label>
                                                </div>
                                            </div>
                                            <div class="linha">
                                                <div class="col">
                                                    <i class="fa-calendar-o fa date-icon" title="{{'seller.dashboard.cards.data-pedido' | translate}}"></i>
                                                    {{cotacao.chosen_date_formatada}}
                                                </div>
                                                <div class="col">
                                                    <i class="fa-tag fa order-icon"></i>
                                                    #{{cotacao.order_id}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer rodape-pedido">
                                            <button type="button" class="btn btn-light btn-sm btn-vgcomprar" (click)="goToConfirmarPedido(cotacao)">{{ 'seller.dashboard.cards.mais-detalhes' | translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </ng-scrollbar>
                    </div>
                } @else {
                    <div class="text-center nenhum-encontrado">
                        <img alt="" src="../../../assets/imagens/etiqueta.png" height="156" width="156">
                        <p>{{'seller.dashboard.cards.nenhum-pedido-encontrado' | translate}}</p>
                    </div>
                }
            </div>
            <div class="col-md-3 column3">
                @if (column03Quotations.length > 0) {
                    <div class="contentvgac_cotacao">
                        <ng-scrollbar class="{{ accountService.obterCssInIframe() }}">
                            @for (cotacao of column03Quotations; track cotacao) {
                                <div class="card card-primary card">
                                    <div>
                                        <div class="card-body">
                                            <div class="statuscard">
                                                <label class="badge paddlab {{cotacao.class_name}}"><i class="fa fa-calendar-o"></i> {{cotacao.status_entrega}}</label>
                                            </div>
                                            @if (cotacao.has_cashback) {
                                                <div class="cashback" tooltip="{{ 'seller.dashboard.cards.cashback-pedido' | translate}}"><b><i class="fa fa-usd text-success"></i></b></div>
                                            }
                                            <div class="row">
                                                <div class="col-md-12 d-flex flex-wrap align-items-center">
                                                    <div title="{{cotacao.brand}}" class="float-start media-box-object rounded-circle marca {{cotacao.brand}}"></div>
                                                    <label class="truncate mb0">{{cotacao.vehicle}}</label>
                                                </div>
                                            </div>
                                            <div class="linha">
                                                <div class="col">
                                                    <i class="fa-car fa car-icon"></i>
                                                    <label class="text-normal truncate repairer-name">{{cotacao.repairer_name}}</label>
                                                </div>
                                                @if (cotacao.tem_devolucao || cotacao.is_troca) {
                                                    <div class="col">
                                                        <i class="fa fa-exclamation-triangle text-danger troca-dev-icon"></i>
                                                        <label class="text-normal text-danger troca-dev-text">{{ ('seller.dashboard.cards.tem' + (cotacao.tem_devolucao ? '-devolucao' : '') + (cotacao.is_troca ? '-troca' : '')) | translate }}
                                                        </label>
                                                    </div>
                                                }
                                            </div>
                                            <div class="linha">
                                                <div class="col">
                                                    <i class="fa-calendar-o fa date-icon" title="{{'seller.dashboard.cards.data-pedido' | translate}}"></i>
                                                    {{cotacao.chosen_date_formatada}}
                                                </div>
                                                <div class="col">
                                                    <i class="fa-tag fa order-icon"></i>
                                                    #{{cotacao.order_id}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer rodape-pedido">
                                            <button type="button" class="btn btn-light btn-sm btn-vgcomprar" (click)="goToConfirmarEntrega(cotacao)">{{ 'seller.dashboard.cards.mais-detalhes' | translate}}</button>
                                            @if (cotacao.status >= StatusIdEnum.Confirmado) {
                                                <span class="inserir-nota" (click)="uploadNotaFiscalAbrir($event)">
                                                    <a href="javascript:void(0)" [ngClass]="{ 'tem-inconsistencia': !cotacao.tem_nota_fiscal || cotacao.tem_inconsistencias }">
                                                        @if (!cotacao.tem_nota_fiscal || cotacao.tem_inconsistencias) {
                                                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                        }
                                                        {{ 'seller.dashboard.cards.nota.inserir' | translate}}
                                                    </a>
                                                    <input type="file" accept="text/xml" (change)="uploadNotaFiscalGravar($event, cotacao)" />
                                                </span>
                                            }
                                        </div>
                                        <ngx-loading [show]="cotacao.loadingNF" [template]="templateCotacao"></ngx-loading>
                                    </div>
                                </div>
                            }
                        </ng-scrollbar>
                    </div>
                } @else {
                    <div class="text-center nenhum-encontrado">
                        <img alt="" src="../../../assets/imagens/etiqueta.png" height="156" width="156">
                        <p>{{'seller.dashboard.cards.nenhum-pedido-encontrado' | translate}}</p>
                    </div>
                }
            </div>
            <div class="col-md-3 column4">
                @if (column04Quotations.length > 0) {
                    <div class="contentvgac_cotacao">
                        <ng-scrollbar class="{{ accountService.obterCssInIframe() }}">
                            <div class="conteudo relcard coluna-4">
                                @for (cotacao of column04Quotations; track cotacao) {
                                    <div class="card card-primary card">
                                        <div>
                                            <div class="card-body">
                                                @if (cotacao.has_cashback) {
                                                    <div class="cashback" tooltip="{{ 'seller.dashboard.cards.cashback-pedido' | translate}}"><b><i class="fa fa-usd text-success"></i></b></div>
                                                }
                                                <div class="row">
                                                    <div class="col-md-12 d-flex flex-wrap align-items-center">
                                                        <div title="{{cotacao.brand}}" class="float-start media-box-object rounded-circle marca {{cotacao.brand}}"></div>
                                                        <label class="truncate mb0">{{cotacao.vehicle}}</label>
                                                    </div>
                                                </div>
                                                <div class="linha">
                                                    <div class="col">
                                                        <i class="fa-car fa car-icon"></i>
                                                        <label class="text-normal truncate repairer-name">{{cotacao.repairer_name}}</label>
                                                    </div>
                                                </div>
                                                <div class="linha">
                                                    <div class="col">
                                                        <i class="fa-calendar-o fa date-icon"></i>
                                                        {{cotacao.chosen_date_formatada}}
                                                    </div>
                                                    <div class="col">
                                                        <i class="fa-tag fa order-icon"></i>
                                                        #{{cotacao.order_id}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer rodape-pedido">
                                                <button type="button" class="btn btn-light btn-sm btn-vgcomprar" (click)="goToConfirmarEntrega(cotacao)">{{ 'seller.dashboard.cards.mais-detalhes' | translate}}</button>
                                                @if (cotacao.status >= StatusIdEnum.Confirmado) {
                                                    <span class="inserir-nota" (click)="uploadNotaFiscalAbrir($event)">
                                                        <a href="javascript:void(0)" [ngClass]="{ 'tem-inconsistencia': !cotacao.tem_nota_fiscal || cotacao.tem_inconsistencias }">
                                                            @if (!cotacao.tem_nota_fiscal || cotacao.tem_inconsistencias) {
                                                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                            }
                                                            {{ 'seller.dashboard.cards.nota.inserir' | translate}}
                                                        </a>
                                                        <input type="file" accept="text/xml" (change)="uploadNotaFiscalGravar($event, cotacao)" />
                                                    </span>
                                                }
                                            </div>
                                            <ngx-loading [show]="cotacao.loadingNF" [template]="templateCotacao"></ngx-loading>
                                        </div>
                                    </div>
                                }
                            </div>
                        </ng-scrollbar>
                    </div>
                } @else {
                    <div class="text-center nenhum-encontrado">
                        <img alt="" src="../../../assets/imagens/etiqueta.png" height="156" width="156">
                        <p>{{'seller.dashboard.cards.nenhum-pedido-encontrado' | translate}}</p>
                    </div>
                }
            </div>
        }
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
<ng-template #templateCotacao></ng-template>
