<div class="visao-geral-detalhe" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [brandIconName]="pedido?.marca"
        [title]="pedido?.veiculo"
        (onGoBack)="sellerService.voltar(null)"
    >
        <button class="btn btn-light nota-fiscal" [ngClass]="{ 'text-danger': !pedido.tem_nota_fiscal || pedido.tem_inconsistencias }" (click)="abrirNotasFiscais()">
            @if (pedido.tem_nota_fiscal && !pedido.tem_inconsistencias) {
                <i class="fa fa-folder-open"></i>
            } @else {
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            }
            &nbsp;{{ 'seller.dashboard.detalhes.botao.notas-fiscais' | translate}}
        </button>
        <button class="btn btn-light imprimir" (click)="imprimir($event)"><i class="fa fa-print"></i>&nbsp;{{ 'seller.dashboard.detalhes.botao.imprimir' | translate}}</button>
    </app-header>

    <dados-cotacao [pedido]="pedido" [voltarHome]="true" [transportadoras]="transportadoras" (atualizarEvent)="atualizarEvent($event)"></dados-cotacao>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>

<iframe src="" style=" width: 100%; height: 0px; border: none;" id="printView"></iframe>
