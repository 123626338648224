<div class="configurar-condicoes-fornecimento" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [title]="'seller.configurar-condicoes-fornecimento.titulo' | translate"
    ></app-header>
    <div class="card card-default">
        <mat-stepper linear #stepper>
            <mat-step [editable]="true">
                <ng-template matStepLabel>{{ 'seller.configurar-condicoes-fornecimento.passos.passo-1.subtitulo' | translate}}</ng-template>
                <ng-scrollbar class="ps-total">
                    <div class="conteudo-passo mb0">
                        <fieldset class="mb0 pb0">

                            <section class="forma-pagamento d-flex flex-wrap striped">
                                <header>
                                    <div class="linha">
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-1.colunas.forma' | translate}}</div>
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-1.colunas.valor-de' | translate}}</div>
                                        <div class="col"></div>
                                        <div class="col"></div>
                                    </div>
                                    <form name="formaPagamentoForm" class="form-validate linha" [formGroup]="formaPagamentoForm" (ngSubmit)="formaPagamento_Adicionar()">
                                        <div class="col">
                                            <select class="form-select form-select-sm m-b" formControlName="payment_type_id" [disabled]="(formasFiltradas.length === 0) ? 'disabled' : null">
                                                @if (formasFiltradas.length === 0) {
                                                    <option value="0">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-1.sem-forma' | translate}}</option>
                                                } @else {
                                                    @for (forma of formasFiltradas; track forma) {
                                                        <option value="{{forma.id}}">{{ forma.texto_x }}</option>
                                                    }
                                                }
                                            </select>
                                        </div>
                                        <div class="col">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text">
                                                    <span>R$</span>
                                                </span>
                                                <input type="text" class="form-control form-control-sm" name="start_value" formControlName="start_value" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" maxlength="6" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <input type="submit" [disabled]="formasFiltradas.length === 0 ? 'disabled' : null" value="{{'seller.configurar-condicoes-fornecimento.passos.botoes.adicionar' | translate}}" class="btn btn-sm btn-success" />
                                        </div>
                                        <div class="col"></div>
                                    </form>
                                    <div class="linha exemplo"><div class="col">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-1.exemplo' | translate}}</div></div>
                                </header>
                                <div class="corpo">
                                    <ng-scrollbar>
                                        @if (formasPagamento?.length <= 0) {
                                            <div class="vazio">
                                                {{'seller.configurar-condicoes-fornecimento.passos.passo-1.vazio' | translate}}
                                            </div>
                                        } @else {
                                            @for (forma of formasPagamento; track forma) {
                                                <div class="linha">
                                                    <div class="col table-cell truncate">{{ forma.payment_type_name }}</div>
                                                    <div class="col table-cell text-end">{{ forma.start_value | currency:'R$ ' }}</div>
                                                    <div class="col table-cell">
                                                        <button class="btn btn-sm btn-danger" (click)="formaPagamento_Remover(forma.payment_type_id)">
                                                            <i class="fa fa-trash"></i>
                                                            <span>{{'seller.configurar-condicoes-fornecimento.passos.botoes.remover' | translate}}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col"></div>
                                                </div>
                                            }
                                        }
                                    </ng-scrollbar>
                                </div>
                            </section>

                        </fieldset>
                    </div>
                </ng-scrollbar>
                <div>
                    <button class="btn btn-primary" (click)="showNextStep()">{{ 'elemento.generico-botao.proximo' | translate }}</button>
                </div>
            </mat-step>
            <mat-step [editable]="true">
                <ng-template matStepLabel>{{ 'seller.configurar-condicoes-fornecimento.passos.passo-2.subtitulo' | translate}}</ng-template>
                <ng-scrollbar class="ps-total">
                    <div class="conteudo-passo mb0">
                        <fieldset class="mb0 pb0">

                            <section class="frete d-flex flex-wrap striped">
                                <header>
                                    <div class="linha">
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-2.colunas.regiao' | translate}}</div>
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-2.colunas.conta-comprador' | translate}}</div>
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-2.colunas.valor-frete' | translate}}</div>
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-2.colunas.valor-minimo-gratis' | translate}}</div>
                                        <div class="col"></div>
                                    </div>
                                    <form name="freteForm" class="form-validate linha" [formGroup]="freteForm" (ngSubmit)="frete_Adicionar()">
                                        <div class="col">
                                            <select class="form-select form-select-sm m-b" formControlName="regiao" [disabled]="(regioesFiltradas.length === 0) ? 'disabled' : null">
                                                @if (regioesFiltradas.length === 0) {
                                                    <option value="0">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-2.sem-regiao' | translate}}</option>
                                                } @else {
                                                    @for (regiao of regioesFiltradas; track regiao) {
                                                        <option value="{{regiao.region_id}}">{{ regiao.region_name }}</option>
                                                    }
                                                }
                                            </select>
                                        </div>
                                        <div class="col">
                                            <select class="form-select form-select-sm m-b" name="frete_conta_comprador" formControlName="frete_conta_comprador" (change)="frete_MudarContaComprador()">
                                                <option value="1">{{ 'elemento.generico-select.sim' | translate}}</option>
                                                <option value="2" selected>{{ 'elemento.generico-select.nao' | translate}}</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text">
                                                    <span>R$</span>
                                                </span>
                                                <input type="text" class="form-control form-control-sm" [readonly]="(freteForm.value.frete_conta_comprador === '1') ? 'disabled' : null" name="frete" formControlName="frete" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" maxlength="6" (blur)="frete_ValidarFreteGratuito()" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="input-group input-group-sm">
                                                <span class="input-group-text">
                                                    <span>R$</span>
                                                </span>
                                                <input type="text" class="form-control form-control-sm" [readonly]="(freteForm.value.frete_conta_comprador === '1' || freteForm.value.frete <= 0) ? 'disabled' : null" name="frete_gratuito" formControlName="frete_gratuito" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" maxlength="6" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <input type="submit" [disabled]="regioesFiltradas.length === 0 ? 'disabled' : null" value="{{'seller.configurar-condicoes-fornecimento.passos.botoes.adicionar' | translate}}" class="btn btn-sm btn-success" />
                                        </div>
                                    </form>
                                    <div class="linha exemplo"><div class="col">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-2.exemplo' | translate}}</div></div>
                                </header>
                                <div class="corpo">
                                    <ng-scrollbar>
                                        @if (fretes?.length <= 0) {
                                            <div class="vazio">
                                                {{'seller.configurar-condicoes-fornecimento.passos.passo-2.vazio' | translate}}
                                            </div>
                                        } @else {
                                            @for (frete of fretes; track frete) {
                                                <div class="linha">
                                                    <div class="col table-cell truncate">{{ frete.region_name }}</div>
                                                    <div class="col table-cell text-center">{{ (frete.buyer_payer ? 'elemento.generico-select.sim' : 'elemento.generico-select.nao') | translate }}</div>
                                                    <div class="col table-cell text-end">{{ frete.shipping_cost | currency:'R$ ' }}</div>
                                                    <div class="col table-cell text-end">{{ frete.min_value | currency:'R$ ' }}</div>
                                                    <div class="col table-cell">
                                                        <button class="btn btn-sm btn-danger" (click)="frete_Remover(frete.region_id)">
                                                            <i class="fa fa-trash"></i>
                                                            <span>{{'seller.configurar-condicoes-fornecimento.passos.botoes.remover' | translate}}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        }
                                    </ng-scrollbar>
                                </div>
                            </section>

                        </fieldset>
                    </div>
                </ng-scrollbar>
                <div>
                    <button class='btn btn-light mr-sm' (click)="showPreviousStep()">{{ 'elemento.generico-botao.anterior' | translate }}</button>
                    <button class="btn btn-primary" (click)="showNextStep()">{{ 'elemento.generico-botao.proximo' | translate }}</button>
                </div>
            </mat-step>
            <mat-step [editable]="true">
                <ng-template matStepLabel>{{ 'seller.configurar-condicoes-fornecimento.passos.passo-3.subtitulo' | translate}}</ng-template>
                <ng-scrollbar class="ps-total">
                    <div class="conteudo-passo mb0">
                        <fieldset class="mb0 pb0">

                            <section class="prazo-entrega d-flex flex-wrap striped">
                                <header>
                                    <div class="linha cabecalho">
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-3.colunas.regiao' | translate}}</div>
                                        <div class="col">{{'seller.configurar-condicoes-fornecimento.passos.passo-3.colunas.prazo' | translate}}</div>
                                        <div class="col">
                                            <div class="input-group">
                                                <span class="input-group-text">
                                                    <span>{{'seller.configurar-condicoes-fornecimento.passos.passo-3.colunas.pedido-fabrica.label' | translate}}</span>
                                                </span>

                                                <select class="form-select form-select-sm m-b" [(ngModel)]="dias_pedido_fabrica">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>

                                                <span class="input-group-text">
                                                    <span>{{'seller.configurar-condicoes-fornecimento.passos.passo-3.colunas.pedido-fabrica.du' | translate}}</span>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                    <form name="prazoForm" class="form-validate linha" [formGroup]="prazoForm" (ngSubmit)="prazoEntrega_Adicionar()">
                                        <div class="col">
                                            <select class="form-select form-select-sm m-b" formControlName="region_id" [disabled]="(regioesFiltradasPrazoEntrega.length === 0) ? 'disabled' : null">
                                                @if (regioesFiltradasPrazoEntrega.length === 0) {
                                                    <option value="0">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-3.sem-regiao' | translate}}</option>
                                                } @else {
                                                    @for (regiao of regioesFiltradasPrazoEntrega; track regiao) {
                                                        <option value="{{regiao.region_id}}">{{ regiao.region_name }}</option>
                                                    }
                                                }
                                            </select>
                                        </div>
                                        <div class="col">
                                            <select class="form-select form-select-sm m-b" formControlName="delivery_time_id">
                                                @for (prazo of lista_prazos; track prazo) {
                                                    <option value="{{prazo.delivery_time_id}}">{{ prazo.delivery_time_name }}</option>
                                                }
                                            </select>
                                        </div>
                                        <div class="col">
                                            <input type="submit" [disabled]="regioesFiltradasPrazoEntrega.length === 0 ? 'disabled' : null" value="{{'seller.configurar-condicoes-fornecimento.passos.botoes.adicionar' | translate}}" class="btn btn-sm btn-success" />
                                        </div>
                                    </form>
                                    <div class="linha exemplo"><div class="col">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-3.exemplo' | translate}}</div></div>
                                </header>
                                <div class="corpo">
                                    <ng-scrollbar>
                                        @if (prazosEntrega?.length <= 0) {
                                            <div class="vazio">
                                                {{'seller.configurar-condicoes-fornecimento.passos.passo-3.vazio' | translate}}
                                            </div>
                                        } @else {
                                            @for (prazo of prazosEntrega; track prazo) {
                                                <div class="linha">
                                                    <div class="col table-cell truncate">{{ prazo.region_name }}</div>
                                                    <div class="col table-cell text-center">{{ prazo.delivery_time_name }}</div>
                                                    <div class="col table-cell">
                                                        <button class="btn btn-sm btn-danger" (click)="prazoEntrega_Remover(prazo.region_id)">
                                                            <i class="fa fa-trash"></i>
                                                            <span>{{'seller.configurar-condicoes-fornecimento.passos.botoes.remover' | translate}}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        }
                                    </ng-scrollbar>
                                </div>
                            </section>

                        </fieldset>
                    </div>
                </ng-scrollbar>
                <div>
                    <button class='btn btn-light mr-sm' (click)="showPreviousStep()">{{ 'elemento.generico-botao.anterior' | translate }}</button>
                    <button class="btn btn-primary" (click)="showNextStep()">{{ 'elemento.generico-botao.proximo' | translate }}</button>
                </div>
            </mat-step>
            <mat-step [editable]="true">
                <ng-template matStepLabel>{{ 'seller.configurar-condicoes-fornecimento.passos.passo-4.subtitulo' | translate}}</ng-template>
                <ng-scrollbar class="ps-total">
                    <div class="conteudo-passo mb0">
                        <fieldset class="mb0 pb0">
                            <legend class="titulo-passo">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-4.subtitulo' | translate}}</legend>
                            <div class="linha exemplo"><div class="col">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-4.exemplo' | translate}}</div></div>
                        </fieldset>
                    </div>
                </ng-scrollbar>
                <div>
                    <button class='btn btn-light mr-sm' (click)="showPreviousStep()">{{ 'elemento.generico-botao.anterior' | translate }}</button>
                    <button class="btn btn-primary" (click)="this.salvar(0)">{{ 'seller.configurar-condicoes-fornecimento.passos.passo-4.botao' | translate }}</button>
                </div>
            </mat-step>
        </mat-stepper>

    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
